import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditSlide from "./edit";
import ReactTooltip from "react-tooltip";

export default function Promocao({ darkMode, tipoLayout, tipoMenu }) {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [promocaoList, setPromocaoList] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [slideShowEdit, setSlideShowEdit] = useState([]);

  useEffect(() => {
    loadPromocao();
  }, []);

  function loadPromocao() {
    Api.get("/promocoes/info")
      .then((response) => {
        setPromocaoList(response.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editPromocao(slide) {
    setSlideShowEdit(slide);
    setOpenModalEdit(true);
  }

  function alteraAtivado(ativado) {
    setLoading(true);
    Api.get(`/promocoes/ativada/${ativado}`)
      .then((response) => {
        loadPromocao();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows =
      promocaoList &&
      promocaoList.map((promocao, index) => {
        const imagem = () => {
          return (
            promocao.imagem && (
              <img
                src={process.env.REACT_APP_FILES_URL + promocao.imagem}
                style={{ maxWidth: "100%", height: "100px" }}
              />
            )
          );
        };

        const ativado = () => {
          return promocao.ativado ? (
            <a
              href="#"
              className="ativado"
              onClick={() => {
                alteraAtivado(0);
              }}
              data-tip="Clique para desativar"
            ></a>
          ) : (
            <a
              href="#"
              className="desativado"
              onClick={() => {
                alteraAtivado(1);
              }}
              data-tip="Clique para ativar"
            ></a>
          );
        };

        return (
          <div className="item" key={index}>
            <div>
              <a
                href="#"
                onClick={() => {
                  editPromocao(promocao);
                }}
                alt="Editar"
                data-tip="Editar"
              >
                <i className="bx bx-edit"></i>
              </a>
              {ativado()}
            </div>
            <div>{imagem()}</div>
            <div>
              <a href={promocao.link} target="_blank">
                {promocao.link}
              </a>
            </div>
          </div>
        );
      });

    return (
      <>
        <div className="grid">
          <div className="header">
            <div></div>
            <div>Imagem</div>
            <div>Link</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Banner Promocional</span>
          <ul className="breadcrumbs">
            <li>Banner Promocional</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          {/* <div className='actions top'>
          <Link to="#" onClick={() => {setOpenModalNew(true)}}>
            <i className='bx bx-plus-circle'></i> Banner
          </Link>
        </div> */}
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {promocaoList && RenderContent()}
        </main>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <EditSlide
            data={slideShowEdit}
            loadPromocao={loadPromocao}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
