import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  titulo_pt: yup.string().nullable().required("Nome obrigatório"),
  titulo_en: yup.string().nullable().required("Nome obrigatório"),
  titulo_es: yup.string().nullable().required("Nome obrigatório"),
  descricao_pt: yup.string().nullable().required("Descrição obrigatória"),
  descricao_en: yup.string().nullable().required("Descrição obrigatória"),
  descricao_es: yup.string().nullable().required("Descrição obrigatória"),
});

export default function EditEventos({
  data,
  loadCalendariosemanal,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("titulo_pt", data.titulo_pt);
    setValue("titulo_en", data.titulo_en);
    setValue("titulo_es", data.titulo_es);
    setValue("descricao_pt", data.descricao_pt);
    setValue("descricao_en", data.descricao_en);
    setValue("descricao_es", data.descricao_es);
  }, []);

  const onSubmit = (dataForm) => {
    setLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("titulo_pt", dataForm.titulo_pt);
    bodyFormData.append("titulo_es", dataForm.titulo_es);
    bodyFormData.append("nome_es", dataForm.nome_es);
    bodyFormData.append("descricao_pt", dataForm.descricao_pt);
    bodyFormData.append("descricao_en", dataForm.descricao_en);
    bodyFormData.append("descricao_es", dataForm.descricao_es);
    bodyFormData.append("oldimage", data.imagem);

    const imagefile = document.querySelector("#imagem");
    if (imagefile.files.length > 0) {
      bodyFormData.append("imagem", imagefile.files[0]);
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/calendariosemanal/edit", bodyFormData, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadCalendariosemanal();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  const imagem = () => {
    return (
      data.imagem && (
        <img
          src={process.env.REACT_APP_FILES_URL + data.imagem_thumb_1}
          style={{ maxWidth: "100px" }}
        />
      )
    );
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Evento</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Dia da semana </label>
            <p>
              <strong>{data.diaSemana_pt}</strong>
            </p>
            <span className="error">{errors.diaSemana?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <p>(Dimensões da imagem: 1440px x 900px)</p>
            <input
              type="file"
              nome="imagem"
              id="imagem"
              {...register("imagem")}
            />
            <span className="error">{errors.arquivo?.message}</span>
            {imagem()}
          </Grid>
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={6} sx={6} className="field">
                <label>Nome</label>
                <input type="text" {...register("titulo_pt")} />
                <span className="error">{errors.titulo_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea rows="20" {...register("descricao_pt")}></textarea>
                <span className="error">{errors.descricao_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={6} sx={6} className="field">
                <label>Nome</label>
                <input type="text" {...register("titulo_en")} />
                <span className="error">{errors.titulo_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea rows="20" {...register("descricao_en")}></textarea>
                <span className="error">{errors.descricao_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={6} sx={6} className="field">
                <label>Nome</label>
                <input type="text" {...register("titulo_es")} />
                <span className="error">{errors.titulo_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea rows="20" {...register("descricao_es")}></textarea>
                <span className="error">{errors.descricao_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
