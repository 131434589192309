import React, { useState, useEffect } from "react";
import Api from "../services/api";

export default function SelectAcomodacoes(props) {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadUnidades();
  }, []);

  function loadUnidades() {
    Api.get("/acomodacoes/all")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  let rows =
    list &&
    list.map((unidade, index) => {
      return (
        <option
          value={unidade.id}
          selected={props.selected == unidade.id ? true : false}
          key={index}
        >
          {unidade.nome} | {unidade.nome_pt}
        </option>
      );
    });

  return (
    <select {...props.register} onChange={props.handleChange}>
      <option></option>
      {rows}
    </select>
  );
}
