import React, { useState, useEffect } from "react";
import Api from "../services/api";

export default function SelectPacotes(props) {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadPacotes();
  }, []);

  function loadPacotes() {
    Api.get("/pacotes/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  let rows =
    list &&
    list.map((pacote, index) => {
      return (
        <option
          value={pacote.id}
          selected={props.selected == pacote.id ? true : false}
          key={index}
        >
          {pacote.nome_pt}
        </option>
      );
    });

  return (
    <select {...props.register} onChange={props.handleChange}>
      <option></option>
      {rows}
    </select>
  );
}
