import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({ getContent, editorState, setEditorState }) => {
  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
  };

  const sendContent = () => {
    getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        editorStyle={{ height: "300px" }}
      />
      <textarea
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        style={{ display: "none" }}
      ></textarea>
    </div>
  );
};

export default TextEditor;
