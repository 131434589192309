import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";

const cookies = new Cookies();

const added_data_axios = {
  userId: cookies.get("hdvU"),
  dateNow: moment().format("YYYY-MM-DD h:mm:ss"),
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  auth: {
    username: process.env.REACT_APP_BASIC_AUTH_USER,
    password: process.env.REACT_APP_BASIC_AUTH_PASS,
  },
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
  credentials: "same-origin",

  // transformRequest: [(data) => {
  //     return {...added_data_axios,...data};
  //   }, ...axios.defaults.transformRequest],
});

export default api;
