import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import SelectCardapioCategorias from "../../components/SelectCardapioCategorias";

const validationSchema = yup.object().shape({
  cardapio_categorias_id: yup.string().required("Categoria obrigatória"),
  nome_pt: yup.string().required("Nome do item obrigatório"),
  nome_en: yup.string().required("Nome do item obrigatório"),
  nome_es: yup.string().required("Nome do item obrigatório"),
  descricao_pt: yup.string().required("Descrição obrigatório"),
  descricao_en: yup.string().required("Descrição obrigatório"),
  descricao_es: yup.string().required("Descrição obrigatório"),
  valor: yup.string().notRequired(),
});

export default function CardapioEdit({
  data,
  setOpenModalCardapioEdit,
  loadCardapio,
  loadCardapioCategorias,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("nome_pt", data.nome_pt);
    setValue("nome_en", data.nome_en);
    setValue("nome_es", data.nome_es);
    setValue("descricao_pt", data.descricao_pt);
    setValue("descricao_en", data.descricao_en);
    setValue("descricao_es", data.descricao_es);
  }, []);

  const onSubmit = (dataFaq) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Api.post("/cardapio/edit", dataFaq, axiosConfig)
      .then((response) => {
        console.log(response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadCardapioCategorias();
          loadCardapio();
          setOpenModalCardapioEdit(false);
        } else {
          swal("Erro!", response.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
      });
  };

  return (
    <>
      <h2>Editar Item do cardápio</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Categoria</label>
            <SelectCardapioCategorias
              register={{ ...register("cardapio_categorias_id") }}
              selected={data.cardapio_categorias_id}
            />
            <span className="error">
              {errors.cardapio_categorias_id?.message}
            </span>
          </Grid>
          <Grid item lg={4} md={4} sm={4} sx={4} className="field">
            <label>Valor</label>
            <input
              type="text"
              {...register("valor")}
              defaultValue={data.valor}
            />
            <span className="error">{errors.valor?.message}</span>
          </Grid>
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_pt")} />
                <span className="error">{errors.nome_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea {...register("descricao_pt")} rows="5"></textarea>
                <span className="error">{errors.descricao_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_en")} />
                <span className="error">{errors.nome_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea {...register("descricao_en")} rows="5"></textarea>
                <span className="error">{errors.descricao_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_es")} />
                <span className="error">{errors.nome_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Descrição</label>
                <textarea {...register("descricao_es")} rows="5"></textarea>
                <span className="error">{errors.descricao_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalCardapioEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
