import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import "moment/locale/pt-br";

import ModalNew from "./new";
import ModalEdit from "./edit";
import Mapa from "./mapa";
import MenusDePacotes from "./menus";
import ModalPeriodoNew from "./periodos/new";
import ModalPeriodoEdit from "./periodos/edit";

export default function UnidadesList() {
  const [menus, setMenus] = useState([]);
  const [pacotes, setPacotes] = useState([]);
  const [pacotesPeriodos, setPacotesPeriodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [pacoteEdit, setPacoteEdit] = useState([]);
  const [periodoEdit, setPeriodoEdit] = useState([]);
  const [openModalNewPeriodo, setOpenModalNewPeriodo] = useState(false);
  const [openModalEditPeriodo, setOpenModalEditPeriodo] = useState(false);

  useEffect(() => {
    loadPacotes();
    loadPacotesPeriodos();
    loadPacotesMenus();
  }, []);

  function loadPacotes() {
    Api.get("pacotes/list")
      .then((response) => {
        setPacotes(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadPacotesPeriodos() {
    Api.get("pacotesperiodos/list")
      .then((response) => {
        setPacotesPeriodos(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadPacotesMenus() {
    Api.get("pacotesmenus/list")
      .then((response) => {
        setMenus(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function alteraAtivado(pacote_id, ativado) {
    setLoading(true);
    Api.post(`/pacotes/ativado/${pacote_id}/${ativado}`)
      .then((response) => {
        loadPacotes();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editPacote(pacote) {
    setPacoteEdit(pacote);
    setOpenModalEdit(true);
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Pacotes</span>
          <ul className="breadcrumbs">
            <li>Pacotes</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <a
              href="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
              className=""
            >
              <i className="bx bx-plus-circle"></i> Pacote
            </a>
            <a
              href="#"
              onClick={() => {
                setOpenModalNewPeriodo(true);
              }}
              className=""
            >
              <i className="bx bx-plus-circle"></i> Período
            </a>
          </div>
        </div>
      </div>
    );
  }

  const RenderPacotes = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = pacotes.map((pacote, index) => {
      const ativado = () => {
        return pacote.ativado ? (
          <a
            href="#"
            className="ativado"
            onClick={() => {
              alteraAtivado(pacote.id, 0);
            }}
            data-tip="Clique para desativar"
          ></a>
        ) : (
          <a
            href="#"
            className="desativado"
            onClick={() => {
              alteraAtivado(pacote.id, 1);
            }}
            data-tip="Clique para ativar"
          ></a>
        );
      };

      let mesCompleto = moment().format("YYYY") + "-" + pacote.mes + "-01";
      let mesEscrito =
        pacote.mes == 0 ? "Todos os meses" : moment(mesCompleto).format("MMMM");
      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editPacote(pacote);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            {/* <a href="#" alt="Excluir" data-tip="Excluir"><i className='bx bx-trash'></i></a> */}
            {ativado()}
            {/* <a href="#" alt="Visualizar" data-tip="Visualizar"><i className='bx bx-link-alt'></i></a> */}
          </div>
          <div>
            {pacote.nome_pt}
            <br />
            <small>{mesEscrito}</small>
          </div>
          <div>{pacote.desconto}%</div>
          <div>{pacote.cupom}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid-blog grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
            <div>Desconto</div>
            <div>Cupom</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          <Tabs>
            <TabList>
              <Tab>Pacotes</Tab>
              <Tab>Mapa</Tab>
              <Tab>Menu de pacotes</Tab>
            </TabList>
            <TabPanel>{RenderPacotes()}</TabPanel>
            <TabPanel>
              <Mapa
                pacotes={pacotes}
                pacotesPeriodos={pacotesPeriodos}
                setOpenModalEditPeriodo={setOpenModalEditPeriodo}
                loadPacotesPeriodos={loadPacotesPeriodos}
                setPeriodoEdit={setPeriodoEdit}
              />
            </TabPanel>
            <TabPanel>
              <MenusDePacotes
                menus={menus}
                loadPacotesMenus={loadPacotesMenus}
              />
            </TabPanel>
          </Tabs>
        </main>
      </div>

      <Modal
        open={openModalNew}
        onClose={() => {
          setOpenModalNew(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "modalDefault",
        }}
      >
        <ModalNew setOpenModalNew={setOpenModalNew} loadPacotes={loadPacotes} />
      </Modal>

      <Modal
        open={openModalEdit}
        onClose={() => {
          setOpenModalEdit(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "modalDefault",
        }}
      >
        <ModalEdit
          data={pacoteEdit}
          loadPacotes={loadPacotes}
          setOpenModalEdit={setOpenModalEdit}
        />
      </Modal>

      <Modal
        open={openModalNewPeriodo}
        onClose={() => {
          setOpenModalNewPeriodo(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "modalDefault",
        }}
      >
        <ModalPeriodoNew
          setOpenModalNewPeriodo={setOpenModalNewPeriodo}
          loadPacotesPeriodos={loadPacotesPeriodos}
        />
      </Modal>

      <Modal
        open={openModalEditPeriodo}
        onClose={() => {
          setOpenModalEditPeriodo(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "modalDefault",
        }}
      >
        <ModalPeriodoEdit
          setOpenModalEditPeriodo={setOpenModalEditPeriodo}
          loadPacotesPeriodos={loadPacotesPeriodos}
          periodoEdit={periodoEdit}
        />
      </Modal>
    </>
  );
}
