import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import SelectBgAlinhamento from "../../components/SelectBgAlinhamento";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  alinhamento: yup.string().required("Alinhamento dA imagem obrigatório"),
  arquivo: yup
    .mixed()
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function EditSlideShow({
  data,
  loadSlideShow,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    // reset({ id: data.id})
  }, []);

  const onSubmit = (dataSlide) => {
    setLoading(true);
    const imagefile = document.querySelector("#arquivo");
    const imagefileMobile = document.querySelector("#arquivo_mobile");

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("nome", dataSlide.nome);
    bodyFormData.append("alinhamento", dataSlide.alinhamento);
    bodyFormData.append("botaoLabel", dataSlide.botaoLabel);
    bodyFormData.append("botaoLink", dataSlide.botaoLink);
    if (imagefile.files.length > 0) {
      bodyFormData.append("arquivo", imagefile.files[0]);
    }
    if (imagefileMobile.files.length > 0) {
      bodyFormData.append("arquivo_mobile", imagefileMobile.files[0]);
    }
    bodyFormData.append("oldimage", data.arquivo);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/slideshowhome/edit", bodyFormData, axiosConfig)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadSlideShow();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Slide Show </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <p>(Dimensões da imagem: 1440px x 900px)</p>
            <input
              type="file"
              nome="arquivo"
              id="arquivo"
              {...register("arquivo")}
            />
            <span className="error">{errors.arquivo?.message}</span>
            {data.arquivo && (
              <img
                src={process.env.REACT_APP_FILES_URL + data.arquivo}
                style={{ maxWidth: "100%" }}
              />
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Imagem Mobile</label>
            <p>(Dimensões da imagem: 600px x 900px)</p>
            <input
              type="file"
              nome="arquivo_mobile"
              id="arquivo_mobile"
              {...register("arquivo_mobile")}
            />
            <span className="error">{errors.arquivo?.message}</span>
            {data.arquivo_mobile && (
              <img
                src={process.env.REACT_APP_FILES_URL + data.arquivo_mobile}
                style={{ maxWidth: "100%" }}
              />
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Alinhamento da imagem</label>
            <SelectBgAlinhamento
              inputName="alinhamento"
              selected={data.alinhamento}
              register={register}
            />
            <span className="error">{errors.alinhamento?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Legenda</label>
            <input type="text" {...register("nome")} defaultValue={data.nome} />
            <span className="error">{errors.nome?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Label do botão</label>
            <input
              type="text"
              {...register("botaoLabel")}
              defaultValue={data.botaoLabel}
            />
            <span className="error">{errors.botaoLabel?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Link do botão</label>
            <input
              type="text"
              {...register("botaoLink")}
              defaultValue={data.botaoLink}
            />
            <span className="error">{errors.botaoLink?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field"></Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
