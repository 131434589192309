import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  nome_pt: yup
    .string()
    .nullable()
    .required("Nome do plano do coworking obrigatório"),
  nome_en: yup
    .string()
    .nullable()
    .required("Nome do plano do coworking obrigatório"),
  nome_es: yup
    .string()
    .nullable()
    .required("Nome do plano do coworking obrigatório"),
  descricao_pt: yup.string().nullable().required("Descrição obrigatório"),
  descricao_en: yup.string().nullable().required("Descrição obrigatório"),
  descricao_es: yup.string().nullable().required("Descrição obrigatório"),
  imagem: yup
    .mixed()
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function EditCoworking({
  data,
  loadCoworking,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("nome_pt", data.nome_pt);
    setValue("nome_en", data.nome_en);
    setValue("nome_es", data.nome_es);
    setValue("descricao_pt", data.descricao_pt);
    setValue("descricao_en", data.descricao_en);
    setValue("descricao_es", data.descricao_es);
    setValue("subtitulo_pt", data.subtitulo_pt);
    setValue("subtitulo_en", data.subtitulo_en);
    setValue("subtitulo_es", data.subtitulo_es);
    setValue("caracteristicas_pt", data.caracteristicas_pt);
    setValue("caracteristicas_en", data.caracteristicas_en);
    setValue("caracteristicas_es", data.caracteristicas_es);
  }, []);

  const onSubmit = (dataForm) => {
    setLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("nome_pt", dataForm.nome_pt);
    bodyFormData.append("nome_en", dataForm.nome_en);
    bodyFormData.append("nome_es", dataForm.nome_es);
    bodyFormData.append("descricao_pt", dataForm.descricao_pt);
    bodyFormData.append("descricao_en", dataForm.descricao_en);
    bodyFormData.append("descricao_es", dataForm.descricao_es);
    bodyFormData.append("subtitulo_pt", dataForm.subtitulo_pt);
    bodyFormData.append("subtitulo_en", dataForm.subtitulo_en);
    bodyFormData.append("subtitulo_es", dataForm.subtitulo_es);
    bodyFormData.append("caracteristicas_pt", dataForm.caracteristicas_pt);
    bodyFormData.append("caracteristicas_en", dataForm.caracteristicas_en);
    bodyFormData.append("caracteristicas_es", dataForm.caracteristicas_es);
    bodyFormData.append("oldimage", data.imagem);

    const imagefile = document.querySelector("#imagem");
    if (imagefile.files.length > 0) {
      bodyFormData.append("imagem", imagefile.files[0]);
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/coworking/edit", bodyFormData, axiosConfig)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadCoworking();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  const imagem = () => {
    return (
      data.imagem && (
        <img
          src={process.env.REACT_APP_FILES_URL + data.imagem}
          style={{ maxWidth: "100px" }}
        />
      )
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Plano Coworking</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Grid item lg={6} md={6} sm={12} sx={12} className="field">
              <label>Imagem</label>
              <p>(Dimensões da imagem: 1440px x 900px)</p>
              <input
                type="file"
                nome="imagem"
                id="imagem"
                {...register("imagem")}
              />
              <span className="error">{errors.arquivo?.message}</span>
              {imagem()}
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input
                      type="text"
                      {...register("nome_pt")}
                      defaultValue={data.nome_pt}
                    />
                    <span className="error">{errors.nome_pt?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input
                      type="text"
                      {...register("subtitulo_pt")}
                      defaultValue={data.subtitulo_pt}
                    />
                    <span className="error">
                      {errors.subtitulo_pt?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_pt")}
                      rows="10"
                      defaultValue={data.descricao_pt}
                    ></textarea>
                    <span className="error">
                      {errors.descricao_pt?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_pt")}
                      rows="10"
                      defaultValue={data.caracteristicas_pt}
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_pt?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input
                      type="text"
                      {...register("nome_en")}
                      defaultValue={data.nome_en}
                    />
                    <span className="error">{errors.nome_en?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input
                      type="text"
                      {...register("subtitulo_en")}
                      defaultValue={data.subtitulo_en}
                    />
                    <span className="error">
                      {errors.subtitulo_en?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_en")}
                      rows="10"
                      defaultValue={data.descricao_en}
                    ></textarea>
                    <span className="error">
                      {errors.descricao_en?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_en")}
                      rows="10"
                      defaultValue={data.caracteristicas_en}
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_en?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input
                      type="text"
                      {...register("nome_es")}
                      defaultValue={data.nome_es}
                    />
                    <span className="error">{errors.nome_es?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input
                      type="text"
                      {...register("subtitulo_es")}
                      defaultValue={data.subtitulo_es}
                    />
                    <span className="error">
                      {errors.subtitulo_es?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_es")}
                      rows="10"
                      defaultValue={data.descricao_es}
                    ></textarea>
                    <span className="error">
                      {errors.descricao_es?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_es")}
                      rows="10"
                      defaultValue={data.caracteristicas_es}
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_es?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
