import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const validationSchema = yup.object().shape({
  nome_pt: yup.string().nullable().required("Nome da suíte obrigatório"),
  nome_en: yup.string().nullable().required("Nome da suíte obrigatório"),
  nome_es: yup.string().nullable().required("Nome da suíte obrigatório"),
  caracteristicas_pt: yup
    .string()
    .nullable()
    .required("Características obrigatório"),
  caracteristicas_en: yup
    .string()
    .nullable()
    .required("Características obrigatório"),
  caracteristicas_es: yup
    .string()
    .nullable()
    .required("Características obrigatório"),
  descricao_pt: yup.string().nullable().required("Descrição obrigatório"),
  descricao_en: yup.string().nullable().required("Descrição obrigatório"),
  descricao_es: yup.string().nullable().required("Descrição obrigatório"),
});

export default function EditAcomodacao({
  data,
  setOpenModalEditAcomodacao,
  getAcomodacoes,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("nome_pt", data.nome_pt);
    setValue("nome_en", data.nome_en);
    setValue("nome_es", data.nome_es);
    setValue("descricao_pt", data.descricao_pt);
    setValue("descricao_en", data.descricao_en);
    setValue("descricao_es", data.descricao_es);
    setValue("caracteristicas_pt", data.caracteristicas_pt);
    setValue("caracteristicas_en", data.caracteristicas_en);
    setValue("caracteristicas_es", data.caracteristicas_es);
  }, []);

  const onSubmit = (data) => {
    setLoading(true);

    console.log("data", data);
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/acomodacoes/edit", data, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          getAcomodacoes();
          setOpenModalEditAcomodacao(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar acomodação </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_pt")}
                  defaultValue={data.nome_pt}
                />
                <span className="error">{errors.nome_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="9" {...register("descricao_pt")}></textarea>
                <span className="error">{errors.descricao_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Características</label>
                <textarea
                  rows="5"
                  {...register("caracteristicas_pt")}
                ></textarea>
                <span className="error">
                  {errors.caracteristicas_pt?.message}
                </span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_en")}
                  defaultValue={data.nome_en}
                />
                <span className="error">{errors.nome_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="9" {...register("descricao_en")}></textarea>
                <span className="error">{errors.descricao_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Características</label>
                <textarea
                  rows="5"
                  {...register("caracteristicas_en")}
                ></textarea>
                <span className="error">
                  {errors.caracteristicas_en?.message}
                </span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_es")}
                  defaultValue={data.nome_es}
                />
                <span className="error">{errors.nome_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="9" {...register("descricao_es")}></textarea>
                <span className="error">{errors.descricao_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Características</label>
                <textarea
                  rows="5"
                  {...register("caracteristicas_es")}
                ></textarea>
                <span className="error">
                  {errors.caracteristicas_es?.message}
                </span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEditAcomodacao(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
