import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import ModalCategoriaNew from "./categorias/new";
import ModalCategoriaEdit from "./categorias/edit";
import ModalCardapioNew from "./new";
import ModalCardapioEdit from "./edit";

export default function Cardapio() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [cardapioCategorias, setCardapioCategorias] = useState([]);
  const [cardapio, setCardapio] = useState([]);
  const [openModalCategoriaNew, setOpenModalCategoriaNew] = useState(false);
  const [openModalCategoriaEdit, setOpenModalCategoriaEdit] = useState(false);
  const [categoriaEdit, setCategoriaEdit] = useState([]);
  const [openModalCardapioNew, setOpenModalCardapioNew] = useState(false);
  const [openModalCardapioEdit, setOpenModalCardapioEdit] = useState(false);
  const [cardapioEdit, setCardapioEdit] = useState([]);

  useEffect(() => {
    loadCardapioCategorias();
    loadCardapio();
  }, []);

  function loadCardapio() {
    Api.get("/cardapio/list")
      .then((response) => {
        setCardapio(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadCardapioCategorias() {
    Api.get("/cardapio/categorias/list")
      .then((response) => {
        setCardapioCategorias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function openCategoriaEdit(categoria) {
    setCategoriaEdit(categoria);
    setOpenModalCategoriaEdit(true);
  }

  function openCardapioEdit(cardapio) {
    setCardapioEdit(cardapio);
    setOpenModalCardapioEdit(true);
  }

  function deleteCardapio(cardapio) {
    swal({
      title: "Excluir Item",
      text: "Deseja realmente excluir o item '" + cardapio.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/cardapio/delete/" + cardapio.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCardapioCategorias();
            loadCardapio();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  function deleteCategoria(categoria) {
    swal({
      title: "Excluir Categoria",
      text:
        "Deseja realmente excluir a categoria '" + categoria.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/cardapio/categorias/delete/" + categoria.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCardapioCategorias();
            loadCardapio();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    return (
      <>
        {cardapioCategorias.map((categoria, index) => {
          return (
            <div key={index}>
              <div className="list-default">
                <Link
                  key={index}
                  data-tip="Editar"
                  to="#"
                  onClick={() => {
                    openCategoriaEdit(categoria);
                  }}
                >
                  <h1>{categoria.nome_pt} </h1>
                </Link>
                {categoria.totalperguntas == 0 ? (
                  <Link
                    to="#"
                    data-tip="Excluir"
                    onClick={() => {
                      deleteCategoria(categoria);
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Link>
                ) : (
                  <Link
                    to={`/cardapio/itens/order/${categoria.id}`}
                    data-tip="Ordenar itens dessa categoria"
                  >
                    <i className="bx bx-border-all"></i>
                  </Link>
                )}
              </div>

              <div className="grid-cardapio grid">
                <div className="content">
                  {cardapio
                    .filter((item) => {
                      if (item.cardapio_categorias_id == categoria.id)
                        return true;
                    })
                    .map((cardapio, index) => {
                      return (
                        <div className="item" key={index}>
                          <div>
                            <Link
                              to="#"
                              data-tip="Editar"
                              onClick={() => {
                                openCardapioEdit(cardapio);
                              }}
                            >
                              <i className="bx bx-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              data-tip="Excluir"
                              onClick={() => {
                                deleteCardapio(cardapio);
                              }}
                            >
                              <i className="bx bx-trash"></i>
                            </Link>
                          </div>
                          <div>
                            <p>
                              {cardapio.nome_pt}
                              <br />
                              <small>{cardapio.descricao}</small>
                            </p>
                          </div>
                          <div>R$ {cardapio.valor}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Cardápio</span>
          <ul className="breadcrumbs">
            <li>Cardápio</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link to={`/cardapio/categories/order`}>
              <i className="bx bx-border-all"></i> Ordenar Categorias
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalCategoriaNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Categoria
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalCardapioNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Item
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          <div className="container-default">
            {cardapio && cardapioCategorias && RenderContent()}
          </div>
        </main>

        <Modal
          open={openModalCategoriaNew}
          onClose={() => {
            setOpenModalCategoriaNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaNew
            setOpenModalCategoriaNew={setOpenModalCategoriaNew}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCategoriaEdit}
          onClose={() => {
            setOpenModalCategoriaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaEdit
            data={categoriaEdit}
            setOpenModalCategoriaEdit={setOpenModalCategoriaEdit}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCardapioNew}
          onClose={() => {
            setOpenModalCardapioNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCardapioNew
            setOpenModalCardapioNew={setOpenModalCardapioNew}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCardapioEdit}
          onClose={() => {
            setOpenModalCardapioEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCardapioEdit
            data={cardapioEdit}
            setOpenModalCardapioEdit={setOpenModalCardapioEdit}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>
      </div>
    </>
  );
}
