import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  nome_pt: yup
    .string()
    .nullable()
    .required("Nome do tipo de acomodação obrigatório"),
  nome_en: yup
    .string()
    .nullable()
    .required("Nome do tipo de acomodação obrigatório"),
  nome_es: yup
    .string()
    .nullable()
    .required("Nome do tipo de acomodação obrigatório"),
  titulo_pt: yup
    .string()
    .nullable()
    .required("Titulo do tipo de acomodação obrigatório"),
  titulo_en: yup
    .string()
    .nullable()
    .required("Titulo do tipo de acomodação obrigatório"),
  titulo_es: yup
    .string()
    .nullable()
    .required("Titulo do tipo de acomodação obrigatório"),
  url: yup.string().nullable().required("URL da página obrigatório"),
  imagem: yup
    .mixed()
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function EditAcomodacaoTipo({
  data,
  setOpenModalEdit,
  loadAcomodacoesTipoes,
}) {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("nome_pt", data.nome_pt);
    setValue("nome_en", data.nome_en);
    setValue("nome_es", data.nome_es);
    setValue("titulo_pt", data.titulo_pt);
    setValue("titulo_en", data.titulo_en);
    setValue("titulo_es", data.titulo_es);
    setValue("url", data.url);
  }, []);

  const onSubmit = (dataForm) => {
    setLoading(true);

    const imagefile = document.querySelector("#imagem");

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("link", dataForm.link);
    bodyFormData.append("nome_pt", dataForm.nome_pt);
    bodyFormData.append("nome_en", dataForm.nome_en);
    bodyFormData.append("nome_es", dataForm.nome_es);
    bodyFormData.append("titulo_pt", dataForm.titulo_pt);
    bodyFormData.append("titulo_en", dataForm.titulo_en);
    bodyFormData.append("titulo_es", dataForm.titulo_es);
    bodyFormData.append("url", dataForm.url);
    bodyFormData.append("userId", cookies.get("hdvU"));
    if (imagefile.files.length > 0) {
      bodyFormData.append("imagem", imagefile.files[0]);
    }
    bodyFormData.append("oldimage", dataForm.imagem);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/acomodacoes/tipos/edit", bodyFormData, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadAcomodacoesTipoes();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  const imagem = () => {
    return (
      data.imagem && (
        <img
          src={process.env.REACT_APP_FILES_URL + data.imagem}
          style={{ maxWidth: "100%" }}
        />
      )
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar tipo de acomodação </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_pt")}
                  defaultValue={data.nome_pt}
                />
                <span className="error">{errors.nome_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo</label>
                <input
                  type="text"
                  {...register("titulo_pt")}
                  defaultValue={data.titulo_pt}
                />
                <span className="error">{errors.titulo_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_en")}
                  defaultValue={data.nome_en}
                />
                <span className="error">{errors.nome_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo</label>
                <input
                  type="text"
                  {...register("titulo_en")}
                  defaultValue={data.titulo_en}
                />
                <span className="error">{errors.titulo_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input
                  type="text"
                  {...register("nome_es")}
                  defaultValue={data.nome_es}
                />
                <span className="error">{errors.nome_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo</label>
                <input
                  type="text"
                  {...register("titulo_es")}
                  defaultValue={data.titulo_es}
                />
                <span className="error">{errors.titulo_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>URL</label>
            <input type="text" {...register("url")} defaultValue={data.url} />
            <span className="error">{errors.url?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <p>(Dimensões da imagem: 430px x 215px)</p>
            <input
              type="file"
              name="imagem"
              id="imagem"
              {...register("imagem")}
            />
            <span className="error">{errors.imagem?.message}</span>
            {imagem()}
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
