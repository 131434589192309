import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import BtnStatus from "../../../components/SelectStatus2";
const validationSchema = yup.object().shape({
  status: yup.string().required("Status obrigatório"),
});

export default function AlteraStatus({
  data,
  setOpenModalStatus,
  loadReservas,
  setLoading,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {}, []);

  const onSubmit = (dataTopic) => {
    setLoading(true);

    let dataSend = {
      ...data,
      new_status: dataTopic.status,
      status: dataTopic.status,
      reservationID: data.cloudbedsreservation_id,
      sendemail: dataTopic.sendemail,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Api.post("/reservas/updatestatus", dataSend, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          setLoading(false);
          swal("Sucesso!", response.data.message, "success");
          loadReservas();
          setOpenModalStatus(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
      });
  };

  return (
    <>
      <h2>Alterar Status da reserva #{data.cloudbedsreservation_id}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Novo status da reserva</label>
            <BtnStatus register={register("status")} />
            <span className="error">{errors.status?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <div className="group">
              <input type="checkbox" {...register("sendemail")} />
              <label>Enviar e-mail</label>
            </div>
            <span className="error">{errors.status?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalStatus(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
