import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import "moment/locale/pt-br";

export default function Menus({ menus, loadPacotesMenus }) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;

  // useEffect(() => {
  //   loadPacotesMenus();
  // }, []);

  function alteraAtivado(menu_id, ativado) {
    setLoading(true);
    Api.post(`/pacotesmenus/ativado/${menu_id}/${ativado}`)
      .then((response) => {
        loadPacotesMenus();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderMenus = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = menus.map((menu, index) => {
      const ativado = () => {
        return menu.ativado ? (
          <a
            href="#"
            className="ativado"
            onClick={() => {
              alteraAtivado(menu.id, 0);
            }}
            data-tip="Clique para desativar"
          ></a>
        ) : (
          <a
            href="#"
            className="desativado"
            onClick={() => {
              alteraAtivado(menu.id, 1);
            }}
            data-tip="Clique para ativar"
          ></a>
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            {/* <a href="#" onClick={() => { editPacote(pacote) }} alt="Editar" data-tip="Editar"><i className='bx bx-edit'></i></a> */}
            {/* <a href="#" alt="Excluir" data-tip="Excluir"><i className='bx bx-trash'></i></a> */}
            {ativado()}
            {/* <a href="#" alt="Visualizar" data-tip="Visualizar"><i className='bx bx-link-alt'></i></a> */}
          </div>
          <div>{menu.nome_pt}</div>
          <div>
            <a
              target="_blank"
              href={process.env.REACT_APP_SITE_URL + "pt/" + menu.link}
            >
              {menu.link}
            </a>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="grid-blog grid">
          <div className="header">
            <div></div>
            <div>Menu</div>
            <div>Link</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      {RenderMenus()}
    </>
  );
}
