import React, { useState, useEffect } from "react";
import Api from "../services/api";
import styles from "../styles/SelectStatus.module.css";

export default function SelectUnidadesFiltro({
  search,
  setSearch,
  isOpenUnidades,
  setIsOpenUnidades,
}) {
  const [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const totalList = list.length;

  useEffect(() => {
    loadUnidades();
  }, []);

  function loadUnidades() {
    Api.get("/unidades/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const handleCheckboxChange = (value) => {
    if (search.unidades.includes(value)) {
      setSearch({
        ...search,
        unidades: search.unidades.filter((val) => val !== value),
      });
    } else {
      setSearch({
        ...search,
        unidades: [...search.unidades, value],
      });
    }
  };

  let rows =
    list &&
    list.map((unidades, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            checked={search.unidades.includes(unidades.cloudbedsId)}
            onChange={() => handleCheckboxChange(unidades.cloudbedsId)}
          />{" "}
          <span>{unidades.nome}</span>
        </li>
      );
    });

  return (
    <>
      <button
        className={styles.btn}
        type="button"
        onClick={() => setIsOpenUnidades(!isOpenUnidades)}
      >
        {`${search.unidades.length} de ${totalList} selecionado`}
      </button>
      <div
        className={`${styles.statusbox} ${isOpenUnidades ? styles.opened : undefined}`}
      >
        <ul>{rows}</ul>
      </div>
    </>
  );
}
