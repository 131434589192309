import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import SelectFaqPerguntas from "../../components/SelectFaqPerguntas";

const validationSchema = yup.object().shape({
  faqcategorias_id: yup.string().required("Categoria obrigatória"),
  pergunta_pt: yup.string().required("Pergunta em português obrigatório"),
  pergunta_en: yup.string().required("Pergunta em inglês obrigatório"),
  pergunta_es: yup.string().required("Pergunta em espanhol obrigatório"),
  resposta_pt: yup.string().required("Resposta em português obrigatório"),
  resposta_en: yup.string().required("Resposta em inglês obrigatório"),
  resposta_es: yup.string().required("Resposta em espanhol obrigatório"),
});

export default function FaqNew({
  setOpenModalFaqNew,
  loadFaq,
  loadFaqCategorias,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (dataFaq) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Api.post("/faq/new", dataFaq, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadFaqCategorias();
          loadFaq();
          setOpenModalFaqNew(false);
          //reset({ name: '', category: '',})
        } else {
          swal("Erro!", response.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
      });
  };

  return (
    <>
      <h2>Nova Pergunta </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Categoria</label>
            <SelectFaqPerguntas
              register={{ ...register("faqcategorias_id") }}
            />
            <span className="error">{errors.faqcategorias_id?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Pergunta</label>
                  <textarea {...register("pergunta_pt")} rows="5"></textarea>
                  <span className="error">{errors.pergunta_pt?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Resposta</label>
                  <textarea {...register("resposta_pt")} rows="5"></textarea>
                  <span className="error">{errors.resposta_pt?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Pergunta</label>
                  <textarea {...register("pergunta_en")} rows="5"></textarea>
                  <span className="error">{errors.pergunta_en?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Resposta</label>
                  <textarea {...register("resposta_en")} rows="5"></textarea>
                  <span className="error">{errors.resposta_en?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Pergunta</label>
                  <textarea {...register("pergunta_es")} rows="5"></textarea>
                  <span className="error">{errors.pergunta_es?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Resposta</label>
                  <textarea {...register("resposta_es")} rows="5"></textarea>
                  <span className="error">{errors.resposta_es?.message}</span>
                </Grid>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalFaqNew(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
