import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../services/api";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SelectMeses from "../../components/SelectMeses";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  nome_pt: yup.string().required("Nome do pacote obrigatório"),
  nome_en: yup.string().required("Nome do pacote obrigatório"),
  nome_es: yup.string().required("Nome do pacote obrigatório"),
  arquivo: yup
    .mixed()
    .test("required", "Selecione uma imagem", (file) => {
      if (file.length === 0) {
        return false;
      } else {
        return true;
      }
    })
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function PacotesNew({ setOpenModalNew, loadPacotes }) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (dataForm) => {
    setLoading(true);
    const imagefile = document.querySelector("#arquivo");

    let bodyFormData = new FormData();
    bodyFormData.append("mes", dataForm.mes);
    bodyFormData.append("nome_pt", dataForm.nome_pt);
    bodyFormData.append("nome_en", dataForm.nome_en);
    bodyFormData.append("nome_es", dataForm.nome_es);
    bodyFormData.append("desconto", dataForm.desconto);
    bodyFormData.append("cupom", dataForm.cupom);
    bodyFormData.append("arquivo", imagefile.files[0]);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/pacotes/new", bodyFormData, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadPacotes();
          setOpenModalNew(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Novo Pacote </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome do Pacote</label>
                <input type="text" {...register("nome_pt")} />
                <span className="error">{errors.nome_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome do Pacote</label>
                <input type="text" {...register("nome_en")} />
                <span className="error">{errors.nome_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome do Pacote</label>
                <input type="text" {...register("nome_es")} />
                <span className="error">{errors.nome_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>

        <Grid container className="form">
          <Grid item lg={3} md={3} sm={3} xs={3} className="field">
            <label>Desconto (%)</label>
            <input type="number" defaultValue={0} {...register("desconto")} />
            <span className="error">{errors.desconto?.message}</span>
          </Grid>
          <Grid item lg={4} md={3} sm={3} xs={3} className="field">
            <label>Cupom</label>
            <input type="text" {...register("cupom")} />
            <span className="error">{errors.cupom?.message}</span>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3} className="field">
            <label>Mês</label>
            <SelectMeses register={register("mes")} tipo={1} />
            <span className="error">{errors.mes?.message}</span>
          </Grid>
          <Grid item lg={12} md={6} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <p>(Dimensões da imagem: ??px x ??px)</p>
            <input
              type="file"
              nome="arquivo"
              id="arquivo"
              {...register("arquivo")}
            />
            <span className="error">{errors.arquivo?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalNew(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
