import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import ModalCategoriaNew from "./categorias/new";
import ModalCategoriaEdit from "./categorias/edit";
import ModalFaqaNew from "./new";
import ModalFaqEdit from "./edit";

export default function Faq() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [faqCategorias, setFaqCategorias] = useState([]);
  const [faq, setFaq] = useState([]);
  const [openModalCategoriaNew, setOpenModalCategoriaNew] = useState(false);
  const [openModalCategoriaEdit, setOpenModalCategoriaEdit] = useState(false);
  const [categoriaEdit, setCategoriaEdit] = useState([]);
  const [openModalFaqNew, setOpenModalFaqNew] = useState(false);
  const [openModalFaqEdit, setOpenModalFaqEdit] = useState(false);
  const [faqEdit, setFaqEdit] = useState([]);

  useEffect(() => {
    loadFaqCategorias();
    loadFaq();
  }, []);

  function loadFaq() {
    Api.get("/faq/list")
      .then((response) => {
        setFaq(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadFaqCategorias() {
    Api.get("/faq/categorias/list")
      .then((response) => {
        setFaqCategorias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function openCategoriaEdit(categoria) {
    setCategoriaEdit(categoria);
    setOpenModalCategoriaEdit(true);
  }

  function openFaqEdit(faq) {
    setFaqEdit(faq);
    setOpenModalFaqEdit(true);
  }

  function deleteFaq(faq) {
    swal({
      title: "Excluir Pergunta",
      text: "Deseja realmente excluir a pergunta '" + faq.pergunta_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/faq/delete/" + faq.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadFaqCategorias();
            loadFaq();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  function deleteCategoria(categoria) {
    swal({
      title: "Excluir Tópico",
      text:
        "Deseja realmente excluir a categoria '" + categoria.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/faq/categorias/delete/" + categoria.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadFaqCategorias();
            loadFaq();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    return (
      <>
        {faqCategorias.map((categoria, index) => {
          return (
            <>
              <div className="list-default">
                <Link
                  key={index}
                  to="#"
                  data-tip="Editar"
                  onClick={() => {
                    openCategoriaEdit(categoria);
                  }}
                >
                  <h1>{categoria.nome_pt} </h1>
                </Link>
                {categoria.totalperguntas == 0 ? (
                  <Link
                    to="#"
                    data-tip="Excluir"
                    onClick={() => {
                      deleteCategoria(categoria);
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Link>
                ) : (
                  <Link to="#"></Link>
                )}
              </div>

              <div className="grid-faq grid">
                <div className="content">
                  {faq
                    .filter((item) => {
                      if (item.faqcategorias_id == categoria.id) return true;
                    })
                    .map((faq, index) => {
                      return (
                        <div className="item" key={index}>
                          <div>
                            <Link
                              to="#"
                              data-tip="Editar"
                              onClick={() => {
                                openFaqEdit(faq);
                              }}
                            >
                              <i className="bx bx-edit"></i>
                            </Link>
                            <Link
                              to="#"
                              data-tip="Excluir"
                              onClick={() => {
                                deleteFaq(faq);
                              }}
                            >
                              <i className="bx bx-trash"></i>
                            </Link>
                          </div>
                          <div>{faq.pergunta_pt}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">FAQ</span>
          <ul className="breadcrumbs">
            <li>FAQ</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalCategoriaNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Categoria
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalFaqNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Pergunta
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {faq && faqCategorias && RenderContent()}
        </main>

        <Modal
          open={openModalCategoriaNew}
          onClose={() => {
            setOpenModalCategoriaNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCategoriaNew
            setOpenModalCategoriaNew={setOpenModalCategoriaNew}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalCategoriaEdit}
          onClose={() => {
            openModalCategoriaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCategoriaEdit
            data={categoriaEdit}
            setOpenModalCategoriaEdit={setOpenModalCategoriaEdit}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalFaqNew}
          onClose={() => {
            setOpenModalFaqNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalFaqaNew
            setOpenModalFaqNew={setOpenModalFaqNew}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalFaqEdit}
          onClose={() => {
            setOpenModalFaqEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalFaqEdit
            data={faqEdit}
            setOpenModalFaqEdit={setOpenModalFaqEdit}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>
      </div>
    </>
  );
}
