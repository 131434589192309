import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../../services/api";
import { Grid } from "@material-ui/core";
import SelectUnidades from "../../../components/SelectUnidades";
import SelectPacotes from "../../../components/SelectPacotes";

const validationSchema = yup.object().shape({
  pacotes_id: yup.string().required("Pacote obrigatório"),
  unidades_id: yup.string().required("Pacote obrigatório"),
  dataCheckin: yup
    .date()
    .nullable()
    .required("Data de check-in obrigatória")
    .default(undefined)
    .transform((curr, orig) => (orig === "" ? null : curr)),
  dataCheckout: yup
    .date()
    .nullable()
    .required("Data de check-out obrigatória")
    .default(undefined)
    .transform((curr, orig) => (orig === "" ? null : curr)),
});

export default function PeriodoNew({
  setOpenModalNewPeriodo,
  loadPacotesPeriodos,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (dataForm) => {
    setLoading(true);

    Api.post("/pacotesperiodos/new", dataForm)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadPacotesPeriodos();
          setOpenModalNewPeriodo(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Novo Perído </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={8} md={6} sm={12} xs={12} className="field">
            <label>Pacote</label>
            <SelectPacotes register={register("pacotes_id")} />
            <span className="error">{errors.pacotes_id?.message}</span>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12} className="field">
            <label>Unidade</label>
            <SelectUnidades register={register("unidades_id")} />
            <span className="error">{errors.unidades_id?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Check-in</label>
            <input type="date" {...register("dataCheckin")} />
            <span className="error">{errors.dataCheckin?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Check-out</label>
            <input type="date" {...register("dataCheckout")} />
            <span className="error">{errors.dataCheckout?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalNewPeriodo(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
