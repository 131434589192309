import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditCalendariosemanal from "./edit";
import ReactTooltip from "react-tooltip";

export default function CalendarioSemanal() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [calendariosemanal, setCalendariosemanal] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [calendariosemanalEdit, setCalendariosemanalEdit] = useState([]);

  useEffect(() => {
    loadCalendariosemanal();
  }, []);

  function loadCalendariosemanal() {
    Api.get("/calendariosemanal/list")
      .then((response) => {
        setCalendariosemanal(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editSlide(evento) {
    setCalendariosemanalEdit(evento);
    setOpenModalEdit(true);
  }

  function deleteSlide(evento) {
    swal({
      title: "Excluir Evento",
      text: "Deseja realmente excluir o evento '" + evento.nome + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/calendariosemanal/delete/" + evento.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCalendariosemanal();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = calendariosemanal.map((evento, index) => {
      const imagem = () => {
        return (
          evento.arquivo && (
            <img
              src={process.env.REACT_APP_FILES_URL + evento.arquivo}
              style={{ height: "100px" }}
            />
          )
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editSlide(evento);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
          </div>
          <div>{evento.diaSemana_pt}</div>
          <div>
            <img
              src={process.env.REACT_APP_FILES_URL + evento.imagem_thumb_1}
              style={{ width: "100px" }}
            />
          </div>
          <div>
            <p>
              {evento.titulo_pt}
              <br />
              <small>{evento.descricao_pt}</small>
            </p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="grid">
          <div className="header">
            <div></div>
            <div>Dia da Semana</div>
            <div>Evento</div>
            <div>Evento</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Calendário Semanal</span>
          <ul className="breadcrumbs">
            <li>Calendário Semanal</li>
            <li>Listagem</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>{calendariosemanal && RenderContent()}</main>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditCalendariosemanal
            data={calendariosemanalEdit}
            loadCalendariosemanal={loadCalendariosemanal}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
