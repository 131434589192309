import React from "react";
import Aside from "../components/Aside";

export default function DashBoard() {
  return (
    <>
      <div className="container-box">
        <Aside />
        <main></main>
      </div>
    </>
  );
}
