export default function SelectMeses(props) {
  const meses1 = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const meses2 = [
    ["0", "Todos os meses"],
    ["1", "Janeiro"],
    ["2", "Fevereiro"],
    ["3", "Março"],
    ["4", "Abril"],
    ["5", "Maio"],
    ["6", "Junho"],
    ["7", "Julho"],
    ["8", "Agosto"],
    ["9", "Setembro"],
    ["10", "Outubro"],
    ["11", "Novembro"],
    ["12", "Dezembro"],
  ];

  let meses = props.tipo == 1 ? meses2 : meses1;

  let rows = meses.map((mes, index) => {
    let isSelected =
      props.tipo == 1
        ? props.selected === mes[0]
          ? true
          : false
        : props.selected == mes
          ? true
          : false;
    let value = props.tipo == 1 ? mes[0] : mes;
    let mesNome = props.tipo == 1 ? mes[1] : mes;

    return (
      <option value={value} selected={isSelected}>
        {mesNome}
      </option>
    );
  });

  return <select {...props.register}>{rows}</select>;
}
