import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditSlide from "./edit";
import NewSlide from "./new";
import ReactTooltip from "react-tooltip";

export default function SlideShow() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [slideshow, setSlideshow] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [slideShowEdit, setSlideShowEdit] = useState([]);

  useEffect(() => {
    loadSlideShow();
  }, []);

  function loadSlideShow() {
    Api.get("/slideshowhome/list")
      .then((response) => {
        setSlideshow(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editSlide(slide) {
    setSlideShowEdit(slide);
    setOpenModalEdit(true);
  }

  function deleteSlide(slide) {
    swal({
      title: "Excluir Slideshow",
      text: "Deseja realmente excluir o Slideshow '" + slide.nome + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/slideshowhome/delete/" + slide.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadSlideShow();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  function alteraAtivado(slide_id, ativado) {
    setLoading(true);
    Api.post(`/slideshowhome/ativado/${slide_id}/${ativado}`)
      .then((response) => {
        loadSlideShow();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = slideshow.map((slide, index) => {
      const imagem = () => {
        return (
          slide.arquivo && (
            <img
              src={process.env.REACT_APP_FILES_URL + slide.arquivo_thumb_1}
            />
          )
        );
      };

      const ativado = () => {
        return slide.ativado == "1" ? (
          <a
            href="#"
            className="ativado"
            onClick={() => {
              alteraAtivado(slide.id, 0);
            }}
            data-tip="Clique para desativar"
          ></a>
        ) : (
          <a
            href="#"
            className="desativado"
            onClick={() => {
              alteraAtivado(slide.id, 1);
            }}
            data-tip="Clique para ativar"
          ></a>
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editSlide(slide);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <a
              href="#"
              onClick={() => {
                deleteSlide(slide);
              }}
              data-tip="Excluir"
            >
              <i className="bx bx-trash"></i>
            </a>
            {ativado()}
          </div>
          <div>{imagem()}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid fotos">
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Slideshow</span>
          <ul className="breadcrumbs">
            <li>Slideshow</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Slide
            </Link>
            <Link
              to="/slideshow/order"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Ordenação
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {slideshow && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <NewSlide
            loadSlideShow={loadSlideShow}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <EditSlide
            data={slideShowEdit}
            loadSlideShow={loadSlideShow}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
