import React, { Component } from "react";
import { render } from "react-dom";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "react-router";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <span>
    <DragHandleIcon />
  </span>
));

const imagem = (slide) => {
  return (
    slide.arquivo && (
      <img
        src={process.env.REACT_APP_FILES_URL + slide.arquivo}
        style={{ height: "100px" }}
      />
    )
  );
};

const SortableItem = sortableElement(({ slide }) => (
  <li>
    <DragHandle />
    {slide.nome_pt}
    {/* {imagem(slide)} */}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="order">{children}</ul>;
});

class CardapioOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria_id: null,
      loading: true,
      itens: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.categoria;
    this.setState({ categoria_id: id });
    this.loadCardapioItens(id);
  }

  componentDidUpdate() {
    console.log(this.state.itens);
  }

  loadCardapioItens(id) {
    Api.get("/cardapio/list")
      .then((response) => {
        let filter = response.data.filter(
          (item) => item.cardapio_categorias_id == id,
        );
        this.setState({ itens: filter, loading: false });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  saveCardapioItens() {
    Api.post(
      `/cardapio/itens/order/${this.state.categoria_id}`,
      this.state.itens,
    )
      .then((response) => {
        if (response.data) {
          swal("Sucesso!", "Ordem alterada com sucesso", "success");
        } else {
          swal("Erro!", "Erro ao alterar a ordem", "error");
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ itens }) => ({
      itens: arrayMove(itens, oldIndex, newIndex),
    }));
    this.saveCardapioItens();
  };

  renderContent() {
    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {this.state.itens.map((slide, index) => (
          <SortableItem key={`item-${slide.id}`} index={index} slide={slide} />
        ))}
      </SortableContainer>
    );
  }

  renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Cardápio {this.state.categoria_id}</span>
          <ul className="breadcrumbs">
            <li>
              <Link to="/cardapio">Cardápio</Link>
            </li>
            <li>Ordenação de itens da cateogoria</li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.loading ? <div className="loading"></div> : null}
        <div className="container-box">
          <Aside />
          <main>
            {this.renderTopPage()}
            <div className="content">
              {this.state.itens && this.renderContent()}
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default withRouter(CardapioOrder);
