import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditSlide from "./edit";
import NewSlide from "./new";
import ReactTooltip from "react-tooltip";

export default function Expedicoes() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [expedicoes, setExpedicoes] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [expedicoesEdit, setExpedicoesEdit] = useState([]);

  useEffect(() => {
    loadExpedicoes();
  }, []);

  function loadExpedicoes() {
    Api.get("/expedicoes/list")
      .then((response) => {
        setExpedicoes(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editSlide(expedicao) {
    setExpedicoesEdit(expedicao);
    setOpenModalEdit(true);
  }

  function deleteSlide(expedicao) {
    swal({
      title: "Excluir Expedição",
      text:
        "Deseja realmente excluir a expedição '" + expedicao.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/expedicoes/delete/" + expedicao.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadExpedicoes();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  function alteraAtivado(id, ativado) {
    setLoading(true);
    Api.get(`/expedicoes/ativada/${id}/${ativado}`)
      .then((response) => {
        loadExpedicoes();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = expedicoes.map((expedicao, index) => {
      const imagem = () => {
        return (
          expedicao.arquivo && (
            <img
              src={process.env.REACT_APP_FILES_URL + expedicao.arquivo}
              style={{ height: "100px" }}
            />
          )
        );
      };

      const ativado = () => {
        return expedicao.ativada ? (
          <a
            href="#"
            className="ativado"
            onClick={() => {
              alteraAtivado(expedicao.id, 0);
            }}
            data-tip="Clique para desativar"
          ></a>
        ) : (
          <a
            href="#"
            className="desativado"
            onClick={() => {
              alteraAtivado(expedicao.id, 1);
            }}
            data-tip="Clique para ativar"
          ></a>
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editSlide(expedicao);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <Link
              to={"expedicoes/" + expedicao.id + "/fotos"}
              alt="Visualizar"
              data-tip="Fotos"
            >
              <i className="bx bx-photo-album"></i>
            </Link>
            <a
              href="#"
              onClick={() => {
                deleteSlide(expedicao);
              }}
              data-tip="Exluir"
            >
              <i className="bx bx-trash"></i>
            </a>
            {ativado()}
          </div>
          <div>{expedicao.nome_pt}</div>
          <div>
            {expedicao.dataInicio_br} à {expedicao.dataFim_br}
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
            <div>Data</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Expedições</span>
          <ul className="breadcrumbs">
            <li>Expedições</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Expedição
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {expedicoes && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <NewSlide
            loadExpedicoes={loadExpedicoes}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditSlide
            data={expedicoesEdit}
            loadExpedicoes={loadExpedicoes}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
