import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import SelectAcomodacoes from "../../components/SelectAcomodacoes";

const validationSchema = yup.object().shape({
  data: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Data da avaliação obrigatório"),
  usuario: yup.string().nullable().required("Nome do usuário obrigatório"),
  titulo: yup.string().nullable().required("Título da avaliação obrigatório"),
  avaliacao: yup.string().nullable().required("Texto da avaliação obrigatório"),
  acomodacoes_id: yup
    .string()
    .nullable()
    .required("Unidade/categoria da acomodação obrigatório"),
  nota: yup
    .number()
    .nullable()
    .typeError("Número inválida")
    .required("Nota da avaliação obrigatória")
    .max(10, "10 é a nota máxima"),
});

export default function EditAvaliacao({
  data,
  loadAvaliacao,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("acomodacoes_id", data.acomodacoes_id);
  }, []);

  const onSubmit = (dataForm) => {
    setLoading(true);

    console.log("dataForm", dataForm);
    Api.post("/avaliacoes/edit", dataForm)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadAvaliacao();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar avaliação</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Data</label>
            <input type="date" {...register("data")} defaultValue={data.data} />
            <span className="error">{errors.data?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Usuário</label>
            <input
              type="text"
              {...register("usuario")}
              defaultValue={data.usuario}
            />
            <span className="error">{errors.usuario?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Título</label>
            <input
              type="text"
              {...register("titulo")}
              defaultValue={data.titulo}
            />
            <span className="error">{errors.titulo?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Avaliação</label>
            <textarea
              {...register("avaliacao")}
              rows="10"
              defaultValue={data.avaliacao}
            ></textarea>
            <span className="error">{errors.avaliacao?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Unidade/Acomodação</label>

            <SelectAcomodacoes
              selected={data.acomodacoes_id}
              register={register("acomodacoes_id")}
            />
            <span className="error">{errors.acomodacoes_id?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} className="field">
            <label>Nota (0/10)</label>
            <input
              type="number"
              {...register("nota")}
              defaultValue={data.nota}
            />
            <span className="error">{errors.nota?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
