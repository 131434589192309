import React, { useEffect, useState } from "react";
import Aside from "../../../components/Aside";
import Api from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import EditAcomodacaoTipo from "./edit";

export default function AcomodacoesTiposList() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [acomodacoesTipos, setAcomodacoesTipos] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [acomodacoesTiposEdit, setAcomodacoesTiposEdit] = useState([]);

  useEffect(() => {
    loadAcomodacoesTipoes();
  }, []);

  function loadAcomodacoesTipoes() {
    Api.get("/acomodacoes/tipos/list")
      .then((response) => {
        setAcomodacoesTipos(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editTexto(acomodacoesTipos) {
    setAcomodacoesTiposEdit(acomodacoesTipos);
    setOpenModalEdit(true);
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Tipos de Acomodações</span>
          <ul className="breadcrumbs">
            <li>Tipos de Acomodações</li>
            <li>Listagem</li>
          </ul>
        </div>
      </div>
    );
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = acomodacoesTipos.map((tipo, index) => {
      return (
        <div className="item" key={index}>
          <div>
            <Link
              to="#"
              alt="Editar"
              data-tip="Editar"
              onClick={() => {
                editTexto(tipo);
              }}
            >
              <i className="bx bx-edit"></i>
            </Link>
          </div>
          <div>{tipo.nome_pt}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid-blog grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalEdit}
        onClose={() => {
          setOpenModalEdit(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal50",
        }}
      >
        <EditAcomodacaoTipo
          data={acomodacoesTiposEdit}
          setOpenModalEdit={setOpenModalEdit}
          loadAcomodacoesTipoes={loadAcomodacoesTipoes}
        />
      </Modal>
    </>
  );
}
