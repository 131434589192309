import React, { useState, useEffect } from "react";
import Api from "../services/api";
import styles from "../styles/SelectAcomodacoesFiltro.module.css";

export default function SelectAcomodacoesFiltro({
  search,
  setSearch,
  isOpenAcomodacoes,
  setIsOpenAcomodacoes,
}) {
  const [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const totalList = list.filter((item) => item.id != 5).length;

  useEffect(() => {
    loadAcomodacoes();
  }, []);

  function loadAcomodacoes() {
    Api.get("/acomodacoes/all")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const handleCheckboxChange = (value) => {
    if (search.roomsTypes.includes(value)) {
      setSearch({
        ...search,
        roomsTypes: search.roomsTypes.filter((val) => val !== value),
      });
    } else {
      setSearch({
        ...search,
        roomsTypes: [...search.roomsTypes, value],
      });
    }
  };

  let rows =
    list &&
    list
      .filter((item) => item.id != 5)
      .map((acomodacao, index) => {
        return (
          <>
            {index == 0 ? (
              <li className={styles.title}>Hostel da Vila</li>
            ) : undefined}
            {acomodacao.cloudbeds_id == "415846" ? (
              <li className={styles.title}>Vilarejo Suítes</li>
            ) : undefined}
            <li key={index}>
              <input
                type="checkbox"
                checked={search.roomsTypes.includes(acomodacao.cloudbeds_id)}
                onChange={() => handleCheckboxChange(acomodacao.cloudbeds_id)}
              />{" "}
              <span>{acomodacao.nome_pt}</span>
            </li>
          </>
        );
      });

  return (
    <>
      <button
        className={styles.btn}
        type="button"
        onClick={() => setIsOpenAcomodacoes(!isOpenAcomodacoes)}
      >
        {`${search.roomsTypes.length} de ${totalList} selecionado`}
      </button>
      <div
        className={`${styles.acomocacoesbox} ${isOpenAcomodacoes ? styles.opened : undefined}`}
      >
        <ul>{rows}</ul>
      </div>
    </>
  );
}
