import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import Styles from "../../styles/MapaPacotes.module.css";
import { zeroEsquerda, criaArrayDiasMes } from "../../helpers/functions";
import { Modal } from "react-responsive-modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Style } from "@mui/icons-material";
import SelectUnidades from "../../components/SelectUnidades";

export default function Mapa({
  pacotes,
  pacotesPeriodos,
  setOpenModalEditPeriodo,
  loadPacotesPeriodos,
  setPeriodoEdit,
}) {
  const [anoAtual, setAnoAtual] = useState(moment().format("YYYY"));
  const [mesAtualTexto, setMesAtualTexto] = useState(moment().format("MMMM"));
  const [mesAtualNumero, setMesAtualNumero] = useState(moment().format("M"));
  const [qtdDiasMesAtual, setQtdDiasMesAtual] = useState(
    moment(moment().format("YYYY-MM"), "YYYY-MM").daysInMonth(),
  );
  const [periodos, setPeriodos] = useState([]);
  const [unidadeSelecionada, setUnidadeSelecionada] = useState(1);

  useEffect(() => {
    setPeriodos(pacotesPeriodos);
  }, []);

  function mesAnterior() {
    let mesAnteriorNumero =
      parseInt(mesAtualNumero) == 1 ? 12 : parseInt(mesAtualNumero) - 1;
    let mesAnteriorTexto = moment(
      moment().format(anoAtual + "-" + mesAnteriorNumero),
    ).format("MMMM");
    let mesAnteriorqtdDias = moment(
      moment().format(anoAtual + "-" + mesAnteriorNumero),
      "YYYY-MM",
    ).daysInMonth();
    const mesAnoAnterior = moment(`${anoAtual}-${mesAnteriorNumero}`).format(
      "YYYY-MM",
    );
    const mesAnoAgora = moment().format("YYYY-MM");

    if (moment(mesAnoAgora).isSameOrBefore(mesAnoAnterior)) {
      if (mesAtualNumero == 1) setAnoAtual(parseInt(anoAtual) - 1);
      setMesAtualNumero(mesAnteriorNumero);
      setQtdDiasMesAtual(mesAnteriorqtdDias);
      setMesAtualTexto(mesAnteriorTexto);
    }
  }

  function mesProximo() {
    console.log("mesAtualNumero", mesAtualNumero);
    let mesProximoNumero =
      parseInt(mesAtualNumero) == 12 ? 1 : parseInt(mesAtualNumero) + 1;
    let mesProximoTexto = moment(
      moment().format(anoAtual + "-" + mesProximoNumero),
    ).format("MMMM");
    let mesProximoqtdDias = moment(
      moment().format(anoAtual + "-" + mesProximoNumero),
      "YYYY-MM",
    ).daysInMonth();

    if (mesAtualNumero == 12) setAnoAtual(parseInt(anoAtual) + 1);

    setMesAtualNumero(mesProximoNumero);
    setQtdDiasMesAtual(mesProximoqtdDias);
    setMesAtualTexto(mesProximoTexto);
  }

  function editPeriodo(periodo) {
    console.log("periodo", periodo);
    setOpenModalEditPeriodo(true);
    setPeriodoEdit(periodo);
  }

  function corPeriodoBox(datas) {
    const colors = [
      "red",
      "blue",
      "yellow",
      "green",
      "purple",
      "pink",
      "cyan",
      "magenta",
      "teal",
      "lime",
      "brown",
      "silver",
    ];
    const primeiraData = moment(datas[0]);
    const ultimaData = moment(datas[datas.length - 1]);

    const diaSemanaPrimeira = primeiraData.day();
    const diaSemanaUltima = ultimaData.day();

    // 0 para Domingo, 1 para Segunda, ..., 6 para Sábado

    if (diaSemanaPrimeira === 0 && diaSemanaUltima === 3) {
      // Domingo a Quarta
      return colors[0];
    } else if (diaSemanaPrimeira === 4 && diaSemanaUltima === 0) {
      // Quinta a Domingo
      return colors[1];
    } else if (diaSemanaPrimeira === 5 && diaSemanaUltima === 1) {
      // Sexta a Segunda
      return colors[2];
    } else if (diaSemanaPrimeira === 5 && diaSemanaUltima === 0) {
      // Sexta a Domingo
      return colors[3];
    } else if (diaSemanaPrimeira === 5 && diaSemanaUltima === 5) {
      // Sexta a Sexta
      return colors[8];
    } else if (diaSemanaPrimeira === 3 && diaSemanaUltima === 0) {
      // Quarta a Domingo
      return colors[4];
    } else if (diaSemanaPrimeira === 0 && diaSemanaUltima === 5) {
      // Domingo a Sexta
      return colors[5];
    } else if (diaSemanaPrimeira === 0 && diaSemanaUltima === 4) {
      // Domingo a Quinta
      return colors[7];
    } else if (diaSemanaPrimeira === 0 && diaSemanaUltima === 0) {
      // Domingo a Domingo
      return colors[6];
    } else {
      return "black";
    }
  }

  function renderGridNavigation() {
    return (
      <div className={Styles.navigation}>
        <div>
          <ArrowBackIosIcon
            onClick={() => {
              mesAnterior();
            }}
          />
        </div>
        <div>
          <span>
            {mesAtualTexto} / {anoAtual}
          </span>
        </div>
        <div>
          <ArrowForwardIosIcon
            onClick={() => {
              mesProximo();
            }}
          />
        </div>
      </div>
    );
  }

  function renderGridDays() {
    let li = [];
    li.push(
      <li key={0} className={Styles.lipacotes}>
        <SelectUnidades
          handleChange={(e) => {
            setUnidadeSelecionada(e.target.value);
          }}
          selected={1}
        />
      </li>,
    );

    for (let i = 1; i <= qtdDiasMesAtual; i++) {
      let dt =
        zeroEsquerda(anoAtual) +
        "-" +
        zeroEsquerda(mesAtualNumero) +
        "-" +
        zeroEsquerda(i);
      let diaSemana = moment(dt).format("ddd");
      let fdsClass =
        diaSemana == "sáb" || diaSemana == "dom" ? Styles.fimdesemana : null;

      li.push(
        <li key={i} className={fdsClass}>
          <p>{diaSemana}</p>
          <p>{moment(dt).format("DD")}</p>
        </li>,
      );
    }

    return <ul className={Styles.days}>{li}</ul>;
  }

  function renderGrid() {
    const dias = criaArrayDiasMes(qtdDiasMesAtual);
    const rows = pacotes
      .filter(
        (item) =>
          zeroEsquerda(item.mes) == zeroEsquerda(mesAtualNumero) ||
          item.mes == 0,
      )
      .map((pacote, index) => {
        return (
          <div className={Styles.pacoteline} key={index}>
            <div>{pacote.nome_pt}</div>
            {dias.map((dia, index) => {
              let dt =
                zeroEsquerda(anoAtual) +
                "-" +
                zeroEsquerda(mesAtualNumero) +
                "-" +
                zeroEsquerda(dia);

              let periodos = pacotesPeriodos
                .filter(
                  (item) =>
                    item.pacotes_id == pacote.id &&
                    item.unidades_id == unidadeSelecionada &&
                    item.datas.find((i) => i == dt),
                )
                .map((item) => {
                  return item;
                });
              //console.log(dt, dates, periodos)

              return (
                <div key={index}>
                  {periodos.map((periodo, index2) => {
                    return (
                      <div
                        key={index2}
                        className={Styles.pacoteDay}
                        style={{
                          backgroundColor: corPeriodoBox(periodo.datas),
                        }}
                        onClick={() => {
                          editPeriodo(periodo);
                        }}
                      ></div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      });

    return rows;
  }

  return (
    <div className={Styles.mapa}>
      {renderGridNavigation()}
      {renderGridDays()}
      {renderGrid()}
    </div>
  );
}
