import React, { useState, useEffect } from "react";
import Aside from "../components/Aside";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";

const validationSchema = yup.object().shape({
  texto_pt: yup.string().required("Texto em português obrigatório"),
  texto_en: yup.string().required("Texto em inglês obrigatório"),
  texto_es: yup.string().required("Texto em espanhol obrigatório"),
});

export default function EditSobrenos() {
  const [tabIndex, setTabIndex] = useState(0);
  const [textos, setTextos] = useState(false);
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    loadTexto();
  }, []);

  function loadTexto() {
    setLoading(true);
    Api.get("/textos/2")
      .then((response) => {
        setTextos(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const onSubmit = (data) => {
    console.log("opaaaa");
    setLoading(true);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/textos/edit", data, axiosConfig)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");

          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Faça seu aniversário</span>
          <ul className="breadcrumbs">
            <li>Faça seu aniversário</li>
            <li>Texto</li>
          </ul>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="form-sobrenos">
        <input type="hidden" {...register("id")} value={textos[0].id} />
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
            </Tab>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
            </Tab>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
            </Tab>
          </TabList>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <textarea
                  rows="20"
                  {...register("texto_pt")}
                  defaultValue={textos[0].texto_pt}
                ></textarea>
                <span className="error">{errors.texto_pt?.message}</span>
              </Grid>
            </Grid>
            <div className="actions">
              <a
                href="#"
                className="cancel"
                onClick={() => {
                  setTabIndex(1);
                }}
              >
                Continuar
              </a>
            </div>
          </TabPanel>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <textarea
                  rows="20"
                  {...register("texto_en")}
                  defaultValue={textos[0].texto_en}
                ></textarea>
                <span className="error">{errors.texto_en?.message}</span>
              </Grid>
            </Grid>
            <div className="actions">
              <a
                href="#"
                className="cancel"
                onClick={() => {
                  setTabIndex(2);
                }}
              >
                Continuar
              </a>
            </div>
          </TabPanel>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <textarea
                  rows="20"
                  {...register("texto_es")}
                  defaultValue={textos[0].texto_es}
                ></textarea>
                <span className="error">{errors.texto_es?.message}</span>
              </Grid>
            </Grid>
            <div className="actions">
              <button type="submit" className="save">
                Salvar
              </button>
            </div>
          </TabPanel>
        </Tabs>
      </form>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {textos && renderContent()}
        </main>
      </div>
    </>
  );
}
