import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Api from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  legenda: yup.string(),
  imagem: yup
    .mixed()
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function EditFoto({
  data,
  loadFotosExpedicao,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    //reset({ id: data.id})
  }, []);

  const onSubmit = (dataFoto) => {
    setLoading(true);
    const imagefile = document.querySelector("#imagem");

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("expedicoes_id", data.expedicoes_id);
    bodyFormData.append("legenda", dataFoto.legenda);
    if (imagefile.files.length > 0) {
      bodyFormData.append("imagem", imagefile.files[0]);
    }
    bodyFormData.append("oldimage", data.imagem);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/expedicoes/fotos/edit", bodyFormData, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadFotosExpedicao();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  const imagem = () => {
    return (
      data.imagem && (
        <img
          src={process.env.REACT_APP_FILES_URL + data.imagem}
          style={{ maxWidth: "100%" }}
        />
      )
    );
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Foto </h2>
      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Legenda</label>
            <input
              type="text"
              {...register("legenda")}
              defaultValue={data.legenda}
            />
            <span className="error">{errors.legenda?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Foto</label>
            <p>(Dimensões da imagem: 1440px x 900px)</p>
            <input
              type="file"
              name="imagem"
              id="imagem"
              {...register("imagem")}
            />
            <span className="error">{errors.imagem?.message}</span>
            {imagem()}
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
