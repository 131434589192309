import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { Chart } from "react-google-charts";

import ReactTooltip from "react-tooltip";

export default function Reservas() {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const [buscasMotor, setBuscasMotor] = useState([]);
  const [buscasTotaisMotor, setBuscasTotaisMotor] = useState([]);
  const [buscasTotaisPorHora, setBuscasTotaisPorHora] = useState([]);
  const [search, setSearch] = useState({
    data: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    loadBuscasMotor();
  }, [page]);

  function loadBuscasMotor() {
    setLoading(true);

    const dataSearch = {
      data: search.data,
    };

    Api.post("/motorreservas/dataspesquisadas/list", dataSearch)
      .then((response) => {
        setBuscasMotor(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });

    Api.post("/motorreservas/dataspesquisadas/maispesquisadas", dataSearch)
      .then((response) => {
        setBuscasTotaisMotor(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
    Api.post("/motorreservas/dataspesquisadas/porhora", dataSearch)
      .then((response) => {
        const novoArray = [["Horario", "total de pesquisas"]];

        for (let i = 0; i < 24; i++) {
          const dadoEncontrado = response.data.find((dado) => dado.hora === i);
          const total = dadoEncontrado ? dadoEncontrado.total : 0;

          novoArray.push([i.toString(), total]);
        }

        setBuscasTotaisPorHora(novoArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const ultimasDatasPesquisadas = () => {
    const rows = buscasMotor.map((busca, index) => {
      return (
        <div className="item" key={index}>
          <div>{moment(busca.checkin).format("DD/MM/YYYY")}</div>
          <div>{moment(busca.checkout).format("DD/MM/YYYY")}</div>
          <div>{busca.hora}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid datas">
          <h2>Últimas datas pesquisadas ({buscasMotor.length})</h2>
          <div className="header">
            <div>Check-in</div>
            <div>Check-out</div>
            <div>Horário</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  const datasMaisPesquisadas = () => {
    const rows = buscasTotaisMotor.map((busca, index) => {
      if (busca.total > 1) {
        return (
          <div className="item" key={index}>
            <div>{moment(busca.checkin).format("DD/MM/YYYY")}</div>
            <div>{moment(busca.checkout).format("DD/MM/YYYY")}</div>
            <div>{busca.total}</div>
          </div>
        );
      } else {
        return "";
      }
    });

    return (
      <>
        <div className="grid datas">
          <h2>Datas mais pesquisadas</h2>
          <div className="header">
            <div>Check-in</div>
            <div>Check-out</div>
            <div>Qtd. de buscas</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  const horariosMaisPesquisados = () => {
    const chartOptions = {
      chartArea: { top: "5%", width: "60%", height: "980px" },
    };

    return (
      <div className="grid datas">
        <h2>Horários mais pesquisados</h2>
        <Chart
          chartType="BarChart"
          width="100%"
          height="980px"
          data={buscasTotaisPorHora}
          options={chartOptions}
        />
      </div>
    );
  };

  const onSubmit = (event) => {
    loadBuscasMotor();
    event.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function renderFilter() {
    return (
      <div className="filter">
        <form onSubmit={onSubmit}>
          <div className="group">
            <label>Data</label>
            <input
              type="date"
              name="data"
              defaultValue={moment().format("DD/MM/YYYY")}
              onChange={handleChange}
            />
          </div>

          <button className="send">
            <i className={"bx bx-search"}></i>
          </button>
        </form>
      </div>
    );
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">
            Datas Pesquisadas em {moment(search.data).format("DD/MM/YYYY")}
          </span>
          <ul className="breadcrumbs">
            <li>Datas Pesquisadas</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            {/* <Link to="#" onClick={() => {setOpenModalNew(true)}}>
            <i className='bx bx-plus-circle'></i> Plano
          </Link> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {renderFilter()}
          <div className="datas-pesquisadas">
            {buscasMotor && ultimasDatasPesquisadas()}
            {buscasMotor && datasMaisPesquisadas()}
            {buscasMotor && horariosMaisPesquisados()}
          </div>
        </main>
      </div>
    </>
  );
}
