import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../../services/api";
import { Grid } from "@material-ui/core";
import moment from "moment";
import "moment/locale/pt-br";

import SelectUnidades from "../../../components/SelectUnidades";
import SelectPacotes from "../../../components/SelectPacotes";

const validationSchema = yup.object().shape({
  pacotes_id: yup.string().required("Pacote obrigatório"),
  unidades_id: yup.string().required("Pacote obrigatório"),
  dataCheckin: yup
    .date()
    .nullable()
    .required("Data de check-in obrigatória")
    .default(undefined)
    .transform((curr, orig) => (orig === "" ? null : curr)),
  dataCheckout: yup
    .date()
    .nullable()
    .required("Data de check-out obrigatória")
    .default(undefined)
    .transform((curr, orig) => (orig === "" ? null : curr)),
});

export default function PeriodoEdit({
  periodoEdit,
  setOpenModalEditPeriodo,
  loadPacotesPeriodos,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", periodoEdit.id);
    setValue("dataCheckin", periodoEdit.dataCheckin);
    setValue("dataCheckout", periodoEdit.dataCheckout);
    setValue("unidades_id", periodoEdit.unidades_id);
    setValue("pacotes_id", periodoEdit.pacotes_id);
  }, []);

  const onSubmit = (dataForm) => {
    setLoading(true);

    Api.post("/pacotesperiodos/edit", dataForm)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadPacotesPeriodos();
          setOpenModalEditPeriodo(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  function openCloudbeds() {
    let dtCheckin = document.getElementById("dataCheckin").value;
    let dtCheckout = document.getElementById("dataCheckout").value;
    let url = `https://hotels.cloudbeds.com/reservas/${periodoEdit.cloudbedsUrlMotor}#checkin=${dtCheckin}&checkout=${dtCheckout}`;
    console.log("dtCheckin", dtCheckin);
    window.open(url);
  }

  function excluirPeriodo(periodo) {
    swal({
      title: "Excluir Período",
      text: `Deseja realmente excluir o período de ${moment(periodo.dataCheckin).format("DD/MM/YYYY")} à ${moment(periodo.dataCheckout).format("DD/MM/YYYY")} do pacote ${periodo.nome_pacote_pt}?`,
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/pacotesperiodos/delete/" + periodo.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadPacotesPeriodos();
            setLoading(false);
            setOpenModalEditPeriodo(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Perído </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={8} md={6} sm={12} xs={12} className="field">
            <label>Pacote</label>
            <SelectPacotes
              register={register("pacotes_id")}
              selected={periodoEdit.pacotes_id}
            />
            <span className="error">{errors.pacotes_id?.message}</span>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12} className="field">
            <label>Unidade</label>
            <SelectUnidades
              register={register("unidades_id")}
              selected={periodoEdit.unidades_id}
            />
            <span className="error">{errors.unidades_id?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Check-in</label>
            <input type="date" {...register("dataCheckin")} id="dataCheckin" />
            <span className="error">{errors.dataCheckin?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={12} sx={12} className="field">
            <label>Check-out</label>
            <input
              type="date"
              {...register("dataCheckout")}
              id="dataCheckout"
            />
            <span className="error">{errors.dataCheckout?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="javascript:void(0)"
            className="cancel"
            onClick={() => {
              setOpenModalEditPeriodo(false);
            }}
          >
            Cancelar
          </a>
          <a
            href="javascript:void(0)"
            target="_blank"
            onClick={() => {
              openCloudbeds();
            }}
            className="cancel"
          >
            Visualizar na Cloudbeds
          </a>
          <a
            href="javascript:void(0)"
            className="cancel"
            onClick={() => {
              excluirPeriodo(periodoEdit);
            }}
          >
            Excluir período
          </a>
        </div>
      </form>
    </>
  );
}
