import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styles from "../styles/Login.module.css";
import Api from "../services/api";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const validationSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  senha: yup.string().required("Senha obrigatória"),
});

export default function Login() {
  const cookies = new Cookies();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (cookies.get("hdvU")) history.push("/dashboard");
  }, []);

  const onSubmit = (data) => {
    console.log(data);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Api.post("usuarios/login", data, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          const cookies = new Cookies();
          cookies.set("hdvU", response.data.user.id, { path: "/" });
          cookies.set("hdvN", response.data.user.nome, { path: "/" });
          history.push("/dashboard");
        } else {
          swal("Erro!", "Usuário ou senha inválidos", "error");
          reset({ password: "" });
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro: " + err);
      });
  };

  useEffect(() => {
    //if(cookies.get('bnSisUser'))  history.push("/dashboard");
  }, []);

  return (
    <main className={styles.login}>
      <div
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/login-bg.jpg"
          })`,
        }}
      >
        a
      </div>
      <div>
        <img src={process.env.PUBLIC_URL + "/images/logo.png"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>E-mail</label>
          <input type="text" {...register("email")} />
          <span>{errors.email?.message}</span>
          <label>Senha</label>
          <input type="password" {...register("senha")} />
          <span>{errors.senha?.message}</span>
          <button type="submit">Logar</button>
        </form>
      </div>
    </main>
  );
}
