import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditAvaliacao from "./edit";
import NewAvaliacao from "./new";
import ReactTooltip from "react-tooltip";
import moment from "moment";

export default function Avaliacao() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [avaliacoesEdit, setAvaliacoesEdit] = useState([]);

  useEffect(() => {
    loadAvaliacao();
  }, []);

  function loadAvaliacao() {
    Api.get("/avaliacoes/list")
      .then((response) => {
        setAvaliacoes(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editAvaliacao(avaliacao) {
    setAvaliacoesEdit(avaliacao);
    setOpenModalEdit(true);
  }

  function deleteAvaliacao(avaliacao) {
    swal({
      title: "Excluir avaliação",
      text: "Deseja realmente excluir a avaliação selecionada ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/avaliacoes/delete/" + avaliacao.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadAvaliacao();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = avaliacoes.map((avaliacoes, index) => {
      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editAvaliacao(avaliacoes);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <a
              href="#"
              onClick={() => {
                deleteAvaliacao(avaliacoes);
              }}
              data-tip="Exluir"
            >
              <i className="bx bx-trash"></i>
            </a>
          </div>
          <div>{moment(avaliacoes.data).format("DD/MM/YYYY")}</div>
          <div>{avaliacoes.usuario}</div>
          <div>
            {avaliacoes.titulo}
            <br />
            <small>{avaliacoes.avaliacao}</small>
          </div>
          <div>
            {avaliacoes.nome_unidades}
            <br />
            {avaliacoes.nome_acomodacoes}
          </div>
          <div>{avaliacoes.nota}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid avaliacoes">
          <div className="header">
            <div></div>
            <div>Data</div>
            <div>Usuário</div>
            <div>Titulo/Avaliação</div>
            <div>Acomodação</div>
            <div>Nota</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Avaliações</span>
          <ul className="breadcrumbs">
            <li>Avaliações</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Avaliação
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {avaliacoes && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <NewAvaliacao
            loadAvaliacao={loadAvaliacao}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditAvaliacao
            data={avaliacoesEdit}
            loadAvaliacao={loadAvaliacao}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
