import React, { useState, useEffect } from "react";
import Api from "../services/api";

export default function SelectCardapioCategorias(props) {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadCardapioCategorias();
  }, []);

  function loadCardapioCategorias() {
    Api.get("/cardapio/categorias/list")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  let rows =
    list &&
    list.map((categoria, index) => {
      return (
        <option
          value={categoria.id}
          selected={props.selected == categoria.id ? true : false}
          key={index}
        >
          {categoria.nome_pt}
        </option>
      );
    });

  return (
    <select {...props.register} onChange={props.handleChange}>
      {rows}
    </select>
  );
}
