export function zeroEsquerda(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

export function criaArrayDiasMes(qtdDias) {
  let array = [];

  for (let i = 1; i <= qtdDias; i++) {
    array.push(i);
  }

  return array;
}
