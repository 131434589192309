import styles from "../styles/BtnStatus.module.css";

export default function BtnStatus({
  status,
  status_nome,
  jsoncloudbeds_error = null,
  onclick = null,
}) {
  if (status == 1) {
    var btnclass = styles.confirmed;
  } else if (status == 2) {
    var btnclass = styles.not_confirmed;
  } else if (status == 3) {
    var btnclass = styles.canceled;
  } else if (status == 4) {
    var btnclass = styles.checked_in;
  } else if (status == 5) {
    var btnclass = styles.checked_out;
  } else if (status == 6) {
    var btnclass = styles.no_show;
  } else if (status == 7) {
    var btnclass = styles.error;
  }

  return (
    <button
      className={`${styles.btn} ${btnclass}`}
      data-tip={jsoncloudbeds_error != null ? jsoncloudbeds_error : ""}
      onClick={() => {
        onclick();
      }}
    >
      {status_nome}
    </button>
  );
}
