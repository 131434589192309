import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  nome_pt: yup.string().nullable().required("Nome da categoria obrigatório"),
  nome_en: yup.string().nullable().required("Nome da categoria obrigatório"),
  nome_es: yup.string().nullable().required("Nome da categoria obrigatório"),
  dataInicio: yup.date().required("Data inicial da expedição obrigatória"),
  dataFim: yup.date().required("Data inicial da expedição obrigatória"),
  descricao_pt: yup.string().nullable().required("Descrição obrigatório"),
  descricao_en: yup.string().nullable().required("Descrição obrigatório"),
  descricao_es: yup.string().nullable().required("Descrição obrigatório"),
  valorTotal: yup
    .string()
    .nullable()
    .required("Valor da expedição obrigatória"),
});

export default function EditExpedicoes({
  data,
  loadExpedicoes,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("nome_pt", data.nome_pt);
    setValue("nome_en", data.nome_en);
    setValue("nome_es", data.nome_es);
    setValue("descricao_pt", data.descricao_pt);
    setValue("descricao_en", data.descricao_en);
    setValue("descricao_es", data.descricao_es);
    setValue("dataInicio", data.dataInicio);
    setValue("dataFim", data.dataFim);
    setValue("valorTotal", data.valorTotal);
  }, []);

  const onSubmit = (data) => {
    setLoading(true);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    console.log(data);
    Api.post("/expedicoes/edit", data, axiosConfig)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadExpedicoes();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Expedição</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={6} md={6} sm={6} sx={6} className="field">
            <label>Início</label>
            <input
              type="date"
              {...register("dataInicio")}
              defaultValue={data.dataFim}
            />
            <span className="error">{errors.dataInicio?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={6} className="field">
            <label>Termino</label>
            <input type="date" {...register("dataFim")} />
            <span className="error">{errors.dataFim?.message}</span>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={6} className="field">
            <label>Valor</label>
            <input type="text" {...register("valorTotal")} />
            <span className="error">{errors.valorTotal?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("nome_pt")} />
                  <span className="error">{errors.nome_pt?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Descrição</label>
                  <textarea {...register("descricao_pt")} rows="10"></textarea>
                  <span className="error">{errors.descricao_pt?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("nome_en")} />
                  <span className="error">{errors.nome_en?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Descrição</label>
                  <textarea {...register("descricao_en")} rows="10"></textarea>
                  <span className="error">{errors.descricao_en?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("nome_es")} />
                  <span className="error">{errors.nome_es?.message}</span>
                </Grid>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Descrição</label>
                  <textarea {...register("descricao_es")} rows="10"></textarea>
                  <span className="error">{errors.descricao_es?.message}</span>
                </Grid>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
