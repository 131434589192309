import React, { Component } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <span>
    <DragHandleIcon />
  </span>
));

const imagem = (slide) => {
  return (
    slide.arquivo && (
      <img
        src={process.env.REACT_APP_FILES_URL + slide.arquivo}
        style={{ height: "100px" }}
      />
    )
  );
};

const SortableItem = sortableElement(({ slide }) => (
  <li>
    <DragHandle />
    {imagem(slide)}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="order">{children}</ul>;
});

class SlideshowOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      slideshow: [],
    };
  }

  componentDidMount() {
    this.loadSlideShow();
  }

  componentDidUpdate() {
    console.log(this.state.slideshow);
  }

  loadSlideShow() {
    Api.get("/slideshowhome/list")
      .then((response) => {
        let filter = response.data.filter((item) => item.ativado == 1);
        this.setState({ slideshow: filter, loading: false });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  saveSlideShowOrder() {
    Api.post("/slideshowhome/order", this.state.slideshow)
      .then((response) => {
        if (response.data) {
          swal("Sucesso!", "Ordem alterada com sucesso", "success");
        } else {
          swal("Erro!", "Erro ao alterar a ordem", "error");
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ slideshow }) => ({
      slideshow: arrayMove(slideshow, oldIndex, newIndex),
    }));
    this.saveSlideShowOrder();
  };

  renderContent() {
    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {this.state.slideshow.map((slide, index) => (
          <SortableItem key={`item-${slide.id}`} index={index} slide={slide} />
        ))}
      </SortableContainer>
    );
  }

  renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Slideshow</span>
          <ul className="breadcrumbs">
            <li>Slideshow</li>
            <li>Ordenação</li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.loading ? <div className="loading"></div> : null}
        <div className="container-box">
          <Aside />
          <main>
            {this.renderTopPage()}
            <div className="content">
              {this.state.slideshow && this.renderContent()}
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default SlideshowOrder;
