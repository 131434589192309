import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  nome_pt: yup.string().required("Nome da categoria obrigatório"),
  nome_en: yup.string().required("Nome da categoria obrigatório"),
  nome_es: yup.string().required("Nome da categoria obrigatório"),
  dataInicio: yup.date().required("Data inicial da expedição obrigatória"),
  dataFim: yup.date().required("Data inicial da expedição obrigatória"),
  descricao_pt: yup.string().required("Descrição obrigatório"),
  descricao_en: yup.string().required("Descrição obrigatório"),
  descricao_es: yup.string().required("Descrição obrigatório"),
  valorTotal: yup.string().required("Valor da expedição obrigatória"),
});

export default function NewCoworking({ loadCoworking, setOpenModalNew }) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    Api.post("/coworking/new", data)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadCoworking();
          setOpenModalNew(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Novo plano coworking </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input type="text" {...register("nome_pt")} />
                    <span className="error">{errors.nome_pt?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input type="text" {...register("subtitulo_pt")} />
                    <span className="error">
                      {errors.subtitulo_pt?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_pt")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.descricao_pt?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_pt")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_pt?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input type="text" {...register("nome_en")} />
                    <span className="error">{errors.nome_en?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input type="text" {...register("subtitulo_en")} />
                    <span className="error">
                      {errors.subtitulo_en?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_en")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.descricao_en?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_en")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_en?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid container className="form">
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Nome</label>
                    <input type="text" {...register("nome_es")} />
                    <span className="error">{errors.nome_es?.message}</span>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={12} className="field">
                    <label>Subtitulo</label>
                    <input type="text" {...register("subtitulo_es")} />
                    <span className="error">
                      {errors.subtitulo_es?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Descrição</label>
                    <textarea
                      {...register("descricao_es")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.descricao_es?.message}
                    </span>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                    <label>Características</label>
                    <textarea
                      {...register("caracteristicas_es")}
                      rows="10"
                    ></textarea>
                    <span className="error">
                      {errors.caracteristicas_es?.message}
                    </span>
                  </Grid>
                </Grid>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalNew(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
