// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BtnStatus_btn__2HOFG {\n  font-size: 12px;\n  padding: 10px;\n  border-radius: 10px;\n  border: none;\n  background-color: #ccc;\n  width: 100px;\n}\n\n.BtnStatus_confirmed__2_WRr {\n  background-color: green;\n  color: #fff;\n}\n.BtnStatus_not_confirmed__3Y7b_ {\n  background-color: yellow;\n}\n.BtnStatus_canceled__yOSyG {\n  background-color: red;\n  color: #fff;\n}\n.BtnStatus_error__3Ivaa {\n  background-color: rgb(170, 20, 20);\n  color: #fff;\n}\n.BtnStatus_checked_out__2UEH- {\n  background-color: rgba(180, 180, 180, 0.151);\n  color: #000000;\n}\n\n.BtnStatus_checked_in__3Hlc9 {\n  background-color: rgba(0, 128, 0, 0.397);\n  color: #fff;\n}\n", "",{"version":3,"sources":["webpack://src/styles/BtnStatus.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,kCAAkC;EAClC,WAAW;AACb;AACA;EACE,4CAA4C;EAC5C,cAAc;AAChB;;AAEA;EACE,wCAAwC;EACxC,WAAW;AACb","sourcesContent":[".btn {\n  font-size: 12px;\n  padding: 10px;\n  border-radius: 10px;\n  border: none;\n  background-color: #ccc;\n  width: 100px;\n}\n\n.confirmed {\n  background-color: green;\n  color: #fff;\n}\n.not_confirmed {\n  background-color: yellow;\n}\n.canceled {\n  background-color: red;\n  color: #fff;\n}\n.error {\n  background-color: rgb(170, 20, 20);\n  color: #fff;\n}\n.checked_out {\n  background-color: rgba(180, 180, 180, 0.151);\n  color: #000000;\n}\n\n.checked_in {\n  background-color: rgba(0, 128, 0, 0.397);\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "BtnStatus_btn__2HOFG",
	"confirmed": "BtnStatus_confirmed__2_WRr",
	"not_confirmed": "BtnStatus_not_confirmed__3Y7b_",
	"canceled": "BtnStatus_canceled__yOSyG",
	"error": "BtnStatus_error__3Ivaa",
	"checked_out": "BtnStatus_checked_out__2UEH-",
	"checked_in": "BtnStatus_checked_in__3Hlc9"
};
export default ___CSS_LOADER_EXPORT___;
