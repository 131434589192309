import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  imagem: yup
    .mixed()
    .test("required", "Selecione uma imagem", (file) => {
      if (file.length === 0) {
        return false;
      } else {
        return true;
      }
    })
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
  link: yup.string().required("Link  obrigatório"),
});

export default function NewBannershome({ loadBannersHome, setOpenModalNew }) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    // reset({ id: data.id})
  }, []);

  const onSubmit = (dataBanner) => {
    setLoading(true);
    const imagefile = document.querySelector("#imagem");

    let bodyFormData = new FormData();
    bodyFormData.append("link", dataBanner.link);
    bodyFormData.append("imagem", imagefile.files[0]);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/bannershome/new", bodyFormData, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadBannersHome();
          setOpenModalNew(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Novo Banner Home </h2>
      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
        <Grid container className="form">
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <input
              type="file"
              name="imagem"
              id="imagem"
              {...register("imagem")}
            />
            <span className="error">{errors.imagem?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Link</label>
            <input type="text" {...register("link")} />
            <span className="error">{errors.link?.message}</span>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalNew(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
