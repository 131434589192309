import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

const validationSchema = yup.object().shape({
  texto_pt: yup.string().nullable().required("Texto sobre obrigatório"),
  texto_en: yup.string().nullable().required("Texto sobre obrigatório"),
  texto_es: yup.string().nullable().required("Texto sobre obrigatório"),
  estrutura_pt: yup.string().nullable().required("Texto estrutura obrigatório"),
  estrutura_en: yup.string().nullable().required("Texto estrutura obrigatório"),
  estrutura_es: yup.string().nullable().required("Texto estrutura obrigatório"),
});

export default function EditTexto({
  data,
  setOpenModalEditTexto,
  loadUnidades,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("texto_pt", data.texto_pt);
    setValue("texto_en", data.texto_en);
    setValue("texto_es", data.texto_es);
    setValue("estrutura_pt", data.estrutura_pt);
    setValue("estrutura_en", data.estrutura_en);
    setValue("estrutura_es", data.estrutura_es);
  }, []);

  const onSubmit = (data) => {
    setLoading(true);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/unidades/edittext", data, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadUnidades();
          setOpenModalEditTexto(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar informações da unidade </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="15" {...register("texto_pt")}></textarea>
                <span className="error">{errors.texto_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Estrutura</label>
                <textarea rows="9" {...register("estrutura_pt")}></textarea>
                <span className="error">{errors.estrutura_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="15" {...register("texto_en")}></textarea>
                <span className="error">{errors.texto_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Estrutura</label>
                <textarea rows="9" {...register("estrutura_en")}></textarea>
                <span className="error">{errors.estrutura_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Sobre</label>
                <textarea rows="15" {...register("texto_es")}></textarea>
                <span className="error">{errors.texto_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Estrutura</label>
                <textarea rows="9" {...register("estrutura_es")}></textarea>
                <span className="error">{errors.estrutura_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEditTexto(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
