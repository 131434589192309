import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import { Link, useParams } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditAcomodacao from "./edit";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";

export default function AcomodacoesList() {
  const { unidade } = useParams();
  const [acomodacao, setAcomodacao] = useState([]);
  const [unidadeInfo, setUnidadeInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [openModalEditAcomodacao, setOpenModalEditAcomodacao] = useState(false);
  const [acomodacaoEdit, setAcomodacaoEdit] = useState([]);

  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  useEffect(() => {
    getAcomodacoes();
    getUnidade();
  }, [itemOffset, itemsPerPage, unidade]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % acomodacao.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setItemOffset(newOffset);
  };

  function getAcomodacoes() {
    Api.get("todasacomodacoes/" + unidade)
      .then((response) => {
        setAcomodacao(response.data);

        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(response.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(response.data.length / itemsPerPage));
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function getUnidade() {
    Api.get("unidades/" + unidade)
      .then((response) => {
        setUnidadeInfo(response.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editAcomodacao(acomodacao) {
    setAcomodacaoEdit(acomodacao);
    setOpenModalEditAcomodacao(true);
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Acomodações / {unidadeInfo.nome}</span>
          <ul className="breadcrumbs">
            <li>Acomodações</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions">
            {/* <a href='./' className=''><i className='bx bx-plus-circle'></i> Novo Usuário</a> */}
          </div>
        </div>
      </div>
    );
  }

  function alteraAtivado(acomodacao_id, ativado) {
    setLoading(true);
    Api.get(`/acomodacao/ativada/${acomodacao_id}/${ativado}`)
      .then((response) => {
        getAcomodacoes();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows =
      currentItems &&
      currentItems.map((acomodacao, index) => {
        const ativado = () => {
          return acomodacao.acomodacao_ativada == "1" ? (
            <a
              href="#"
              className="ativado"
              onClick={() => {
                alteraAtivado(acomodacao.id, 0);
              }}
              data-tip="Clique para desativar"
            ></a>
          ) : (
            <a
              href="#"
              className="desativado"
              onClick={() => {
                alteraAtivado(acomodacao.id, 1);
              }}
              data-tip="Clique para ativar"
            ></a>
          );
        };

        return (
          <div className="item" key={index}>
            <div>
              <Link to="#" data-tip="Editar" title="Editar">
                <i
                  className="bx bx-edit"
                  onClick={() => {
                    editAcomodacao(acomodacao);
                  }}
                ></i>
              </Link>
              <Link
                to={acomodacao.id + "/fotos"}
                data-tip="Fotos"
                title="Fotos"
              >
                <i className="bx bx-photo-album"></i>
              </Link>
              <a
                href={
                  process.env.REACT_APP_SITE_URL +
                  "unidades/" +
                  unidadeInfo.url +
                  "/acomodacoes/" +
                  acomodacao.url
                }
                alt="Visualizar"
                target="_blank"
                data-tip="Visualizar no site"
              >
                <i className="bx bx-link-alt"></i>
              </a>
              {ativado()}
            </div>
            <div>
              <Link to="#">{acomodacao.nome_pt}</Link>
            </div>
          </div>
        );
      });

    return (
      <>
        <div className="grid-blog grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
          </div>
          <div className="content">
            {rows}
            <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
              <div className="totalrows">
                total de registros{" "}
                <strong>{currentItems && acomodacao.length}</strong>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {unidadeInfo && renderTopPage()}
          {RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalEditAcomodacao}
        onClose={() => {
          setOpenModalEditAcomodacao(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal50",
        }}
      >
        <EditAcomodacao
          data={acomodacaoEdit}
          setOpenModalEditAcomodacao={setOpenModalEditAcomodacao}
          getAcomodacoes={getAcomodacoes}
        />
      </Modal>
    </>
  );
}
