import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
// import SelectFaqTypes from '../../../components/s';

const validationSchema = yup.object().shape({
  nome_pt: yup.string().required("Nome da categoria obrigatório"),
  nome_en: yup.string().required("Nome da categoria obrigatório"),
  nome_es: yup.string().required("Nome da categoria obrigatório"),
});

export default function NewCategoria({
  setOpenModalCategoriaNew,
  loadFaq,
  loadFaqCategorias,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (dataTopic) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Api.post("/faq/categorias/new", dataTopic, axiosConfig)
      .then((response) => {
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadFaqCategorias();
          loadFaq();
          setOpenModalCategoriaNew(false);
          reset({ name: "", category: "" });
        } else {
          swal("Erro!", response.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
      });
  };

  return (
    <>
      <h2>Nova Categoria </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Tabs>
            <TabList>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
              </Tab>
              <Tab>
                <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
              </Tab>
            </TabList>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_pt")} />
                <span className="error">{errors.nome_pt?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_en")} />
                <span className="error">{errors.nome_en?.message}</span>
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Nome</label>
                <input type="text" {...register("nome_es")} />
                <span className="error">{errors.nome_es?.message}</span>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalCategoriaNew(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
