import React, { useState, useEffect } from "react";
import Aside from "../../../components/Aside";
import Api from "../../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link, useParams } from "react-router-dom";
import NewFoto from "./new";
import EditFoto from "./edit";
import ReactTooltip from "react-tooltip";

export default function ExperienciaSFotos() {
  const { experiencia } = useParams();
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [infosUnidade, setInfosUnidade] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [fotoEdit, setFotoEdit] = useState([]);

  useEffect(() => {
    loadInfoExperiencia();
    loadFotosExperiencia();
  }, []);

  function loadInfoExperiencia() {
    Api.get("/experiencias/" + experiencia)
      .then((response) => {
        setInfosUnidade(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadFotosExperiencia() {
    Api.get("/experiencias/fotos/list/" + experiencia)
      .then((response) => {
        setFotos(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editFoto(foto) {
    setFotoEdit(foto);
    setOpenModalEdit(true);
  }

  function deleteFoto(foto) {
    swal({
      title: "Excluir foto",
      text: "Deseja realmente excluir a foto selecionada ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/experiencias/fotos/delete/" + foto.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadFotosExperiencia();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">
            Fotos da Experiência / {infosUnidade[0].nome}
          </span>
          <ul className="breadcrumbs">
            <li>Fotos da Experiência</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Foto
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = fotos.map((foto, index) => {
      const imagem = () => {
        return (
          foto.imagem && (
            <img src={process.env.REACT_APP_FILES_URL + foto.imagem_thumb_1} />
          )
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editFoto(foto);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <a
              href="#"
              onClick={() => {
                deleteFoto(foto);
              }}
              data-tip="Excluir"
            >
              <i className="bx bx-trash"></i>
            </a>
          </div>
          <div>{imagem()}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid fotos">
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {infosUnidade[0] && renderTopPage()}
          {fotos && RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalNew}
        onClose={() => {
          setOpenModalNew(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <NewFoto
          experienciasCategoriasId={experiencia}
          loadFotosExperiencia={loadFotosExperiencia}
          setOpenModalNew={setOpenModalNew}
        />
      </Modal>

      <Modal
        open={openModalEdit}
        onClose={() => {
          setOpenModalEdit(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <EditFoto
          data={fotoEdit}
          loadFotosExperiencia={loadFotosExperiencia}
          setOpenModalEdit={setOpenModalEdit}
        />
      </Modal>
    </>
  );
}
