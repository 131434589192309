import React, { useState, useEffect } from "react";
import Api from "../services/api";
import styles from "../styles/SelectStatus.module.css";

export default function SelectReservasOrigens({
  search,
  setSearch,
  isOpenOrigens,
  setIsOpenOrigens,
}) {
  const [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const totalList = list.length;

  useEffect(() => {
    loadStatus();
  }, []);

  function loadStatus() {
    Api.get("/reservas/origens")
      .then((response) => {
        setList(response.data);
        // response.data.map((origem, index) =>{
        //   setSearch({
        //     ...search,
        //     origens: [...search.origens, origem.nome]
        //   });
        // });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const handleCheckboxChange = (value) => {
    if (search.origens.includes(value)) {
      setSearch({
        ...search,
        origens: search.origens.filter((val) => val !== value),
      });
    } else {
      setSearch({
        ...search,
        origens: [...search.origens, value],
      });
    }
  };

  let rows =
    list &&
    list.map((origens, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            checked={search.origens.includes(origens.nome)}
            onChange={() => handleCheckboxChange(origens.nome)}
          />{" "}
          <span>{origens.nome}</span>
        </li>
      );
    });

  return (
    <>
      <button
        className={styles.btn}
        type="button"
        onClick={() => setIsOpenOrigens(!isOpenOrigens)}
      >
        {`${search.origens.length} de ${totalList} selecionado`}
      </button>
      <div
        className={`${styles.statusbox} ${isOpenOrigens ? styles.opened : undefined}`}
      >
        <ul>{rows}</ul>
      </div>
    </>
  );
}
