import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditCoworking from "./edit";
import NewCoworking from "./new";
import ReactTooltip from "react-tooltip";

export default function Coworking() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [coworking, setCoworking] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [coworkingEdit, setCoworkingEdit] = useState([]);

  useEffect(() => {
    loadCoworking();
  }, []);

  function loadCoworking() {
    Api.get("/coworking/list")
      .then((response) => {
        setCoworking(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editCoworking(expedicao) {
    setCoworkingEdit(expedicao);
    setOpenModalEdit(true);
  }

  function deleteCoworking(expedicao) {
    swal({
      title: "Excluir plano",
      text: "Deseja realmente excluir o plano '" + expedicao.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/coworking/delete/" + expedicao.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCoworking();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = coworking.map((expedicao, index) => {
      const imagem = () => {
        return (
          expedicao.arquivo && (
            <img
              src={process.env.REACT_APP_FILES_URL + expedicao.arquivo}
              style={{ height: "100px" }}
            />
          )
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editCoworking(expedicao);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <a
              href="#"
              onClick={() => {
                deleteCoworking(expedicao);
              }}
              data-tip="Exluir"
            >
              <i className="bx bx-trash"></i>
            </a>
          </div>
          <div>{expedicao.nome_pt}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Coworking</span>
          <ul className="breadcrumbs">
            <li>Coworking</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Plano
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {coworking && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <NewCoworking
            loadCoworking={loadCoworking}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditCoworking
            data={coworkingEdit}
            loadCoworking={loadCoworking}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
