import React, { useEffect, useState } from "react";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import EditTexto from "./edittext";

export default function UnidadesList() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [unidades, setUnidades] = useState([]);
  const [openModalEditTexto, setOpenModalEditTexto] = useState(false);
  const [unidadeEdit, setUnidadeEdit] = useState([]);

  useEffect(() => {
    loadUnidades();
  }, []);

  function loadUnidades() {
    Api.get("/unidades/list")
      .then((response) => {
        setUnidades(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editTexto(unidade) {
    setUnidadeEdit(unidade);
    setOpenModalEditTexto(true);
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Unidades</span>
          <ul className="breadcrumbs">
            <li>Unidades</li>
            <li>Listagem</li>
          </ul>
        </div>
      </div>
    );
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = unidades.map((unidade, index) => {
      return (
        <div className="item" key={index}>
          <div>
            <Link
              to="#"
              alt="Editar"
              data-tip="Editar"
              onClick={() => {
                editTexto(unidade);
              }}
            >
              <i className="bx bx-edit"></i>
            </Link>
            <Link
              to={"unidades/" + unidade.id + "/fotos"}
              alt="Visualizar"
              data-tip="Fotos"
            >
              <i className="bx bx-photo-album"></i>
            </Link>
          </div>
          <div>{unidade.nome}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid-blog grid">
          <div className="header">
            <div></div>
            <div>Nome</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalEditTexto}
        onClose={() => {
          setOpenModalEditTexto(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal50",
        }}
      >
        <EditTexto
          data={unidadeEdit}
          setOpenModalEditTexto={setOpenModalEditTexto}
          loadUnidades={loadUnidades}
        />
      </Modal>
    </>
  );
}
