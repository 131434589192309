import React, { useState, useEffect } from "react";
import Api from "../services/api";
import styles from "../styles/SelectStatus.module.css";

export default function SelectStatus2(props) {
  const [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const totalList = list.length;

  useEffect(() => {
    loadStatus();
  }, []);

  function loadStatus() {
    Api.get("/reservas/status")
      .then((response) => {
        setList(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  let rows =
    list &&
    list.map((status, index) => {
      return (
        <option
          value={`${status.id}|${status.referencia}`}
          selected={props.selected == status.id ? true : false}
          key={index}
        >
          {status.nome}
        </option>
      );
    });

  return (
    <select {...props.register} onChange={props.handleChange}>
      <option></option>
      {rows}
    </select>
  );
}
