import React, { Component } from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import Aside from "../../../components/Aside";
import Api from "../../../services/api";
import swal from "sweetalert";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { withRouter } from "react-router";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <span>
    <DragHandleIcon />
  </span>
));

const imagem = (foto) => {
  return (
    foto.imagem_thumb_1 && (
      <img
        src={process.env.REACT_APP_FILES_URL + foto.imagem_thumb_1}
        style={{ height: "100px" }}
      />
    )
  );
};

const SortableItem = sortableElement(({ foto }) => (
  <li>
    <DragHandle />
    {imagem(foto)}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="order">{children}</ul>;
});

class SlideshowOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      unidadesFotos: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.unidade;
    this.loadFotosUnidade(id);
  }

  componentDidUpdate() {
    console.log(this.state.unidadesFotos);
  }

  loadFotosUnidade(unidade) {
    Api.get("/unidades/fotos/" + unidade)
      .then((response) => {
        this.setState({ unidadesFotos: response.data, loading: false });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  saveFotosUnidadeOrder() {
    Api.post("/unidades/fotos/order", this.state.unidadesFotos)
      .then((response) => {
        if (response.data) {
          // swal("Sucesso!", 'Ordem alterada com sucesso', "success");
        } else {
          swal("Erro!", "Erro ao alterar a ordem", "error");
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ unidadesFotos }) => ({
      unidadesFotos: arrayMove(unidadesFotos, oldIndex, newIndex),
    }));
    this.saveFotosUnidadeOrder();
  };

  renderContent() {
    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {this.state.unidadesFotos.map((foto, index) => (
          <SortableItem key={`item-${foto.id}`} index={index} foto={foto} />
        ))}
      </SortableContainer>
    );
  }

  renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Fotos da Unidade / Ordenação</span>
          <ul className="breadcrumbs">
            <li>Fotos da Unidade</li>
            <li>Ordenação</li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.loading ? <div className="loading"></div> : null}
        <div className="container-box">
          <Aside />
          <main>
            {this.renderTopPage()}
            {this.state.unidadesFotos && this.renderContent()}
          </main>
        </div>
      </>
    );
  }
}

export default withRouter(SlideshowOrder);
