export default function SelectBgAlinhamento({ selected, inputName, register }) {
  let itens = [
    ["top", "Topo da imagem"],
    ["center", "Centro da imagem"],
    ["bottom", "Parte de baixo da imagem"],
  ];

  let rows = itens.map((item, index) => {
    let isSelected = selected == item[0] ? true : false;
    return (
      <option key={index} value={item[0]} selected={isSelected}>
        {item[1]}{" "}
      </option>
    );
  });

  return (
    <>
      <select {...register(inputName)}>{rows}</select>
    </>
  );
}
