import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditSlide from "./edit";
import NewSlide from "./new";
import ReactTooltip from "react-tooltip";

export default function BannersHome({ darkMode, tipoLayout, tipoMenu }) {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [banners, setBanners] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [slideShowEdit, setSlideShowEdit] = useState([]);

  useEffect(() => {
    loadBannersHome();
  }, []);

  function loadBannersHome() {
    Api.get("/bannershome/list")
      .then((response) => {
        setBanners(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editSlide(slide) {
    setSlideShowEdit(slide);
    setOpenModalEdit(true);
  }

  function deleteSlide(slide) {
    swal({
      title: "Excluir Slideshow",
      text: "Deseja realmente excluir o banner selecionado ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/bannershome/delete/" + slide.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadBannersHome();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = banners.map((banner, index) => {
      const imagem = () => {
        return (
          banner.imagem && (
            <img
              src={process.env.REACT_APP_FILES_URL + banner.imagem}
              style={{ maxWidth: "100%", height: "100px" }}
            />
          )
        );
      };

      return (
        <div className="item" key={index}>
          <div>
            <a
              href="#"
              onClick={() => {
                editSlide(banner);
              }}
              alt="Editar"
              data-tip="Editar"
            >
              <i className="bx bx-edit"></i>
            </a>
            <a
              href="#"
              onClick={() => {
                deleteSlide(banner);
              }}
              data-tip="Excluir"
            >
              <i className="bx bx-trash"></i>
            </a>
          </div>
          <div>{imagem()}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid">
          <div className="header">
            <div></div>
            <div>Imagem</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Banners Home</span>
          <ul className="breadcrumbs">
            <li>Banners Home</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Banner
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {banners && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <NewSlide
            loadBannersHome={loadBannersHome}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <EditSlide
            data={slideShowEdit}
            loadBannersHome={loadBannersHome}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
